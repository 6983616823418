/* Contacts.css */
.contact-info {
  width: 70%;
  margin: 0 auto;
  padding: 5%;
  font-family: futura;
  font-size: 23px;
}

/* Contacts.css */
.contact-info h1 {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: black;
  position: relative; /* Add this to establish a positioning context */
  text-align: left;
}

.contact-info h1::after {
  content: ''; /* Empty content for the pseudo-element */
  width: 68%; /* Adjust the width of the line as needed */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 40%; /* Position it at the beginning of the h1 element */
  margin-left:-7%;
}

/* Contacts.css */
.contact-info h2 {
  font-size: 22px;
  margin-bottom: 10px;
  color: black;
  text-align: right; /* Aligns the text to the right */
  position: relative; /* Add this to establish a positioning context */
}

.contact-info h2::after {
  content: ''; /* Empty content for the pseudo-element */
  width: calc(35%); /* Adjust the width based on available space */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 1%;
}
/* Add margin-bottom to the h1 with the "follow-us" class */
.contact-info .follow-us {
    margin-top: 15%; /* Adjust the margin as needed */
}
/* Contacts.css */
.contact-info h3 {
  font-size: 23px;
  margin-bottom: 10px;
  color: black;
  
  text-align: right; /* Aligns the text to the right */
  position: relative; /* Add this to establish a positioning context */
}

.contact-info h3::after {
  content: ''; /* Empty content for the pseudo-element */
  width: calc(48%); /* Adjust the width based on available space */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 1%;
}

/* Contacts.css */
.contact-info h4 {
  font-size: 23px;
  margin-bottom: 10px;
  color: black;
  position: relative; 

  text-align: right; /* Aligns the text to the right */
}

.contact-info h4::after {
  content: ''; /* Empty content for the pseudo-element */
  width: calc(60%); /* Adjust the width based on available space */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 1%;
}


/* Contacts.css */
.contact-info h5 {
  font-size: 23px;
  margin-bottom: 10px;
  color: black;
  text-align: right;
  position: relative; /* Add this to establish a positioning context */
}

.contact-info h5::after {
  content: ''; /* Empty content for the pseudo-element */
  width: calc(75% ); /* Adjust the width based on available space */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 1%;
}

/* Contacts.css */
.contact-info h6 {
  font-size: 23px;
  margin-bottom: 10px;
  color: black;
  text-align: right;
  position: relative; /* Add this to establish a positioning context */
}

.contact-info h6::after {
  content: ''; /* Empty content for the pseudo-element */
  width: calc(78% ); /* Adjust the width based on available space */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 1%;
}
.fale{
  text-align: center;
}

.fale2{
  margin-top:15%;
  text-align: center;
}
.contact-info h61 {

  font-size: 28px;

}

@media screen and  (min-width: 1023px) and (max-width: 1599px){
/* Contacts.css */
.contact-info {
  width: 70%;
  margin: 0 auto;
  padding: 5%;
  font-family: futura;
  font-size: 23px;
}

/* Contacts.css */
.contact-info h1 {
  font-size: 26px;
  margin-bottom: 20px;
  color: black;
  position: relative; /* Add this to establish a positioning context */
  text-align: left;
}

.contact-info h1::after {
  content: ''; /* Empty content for the pseudo-element */
  width: 60%; /* Adjust the width of the line as needed */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 47%; /* Position it at the beginning of the h1 element */
  margin-left:-7%;
}

/* Contacts.css */
.contact-info h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color: black;
  text-align: right; /* Aligns the text to the right */
  position: relative; /* Add this to establish a positioning context */
}

.contact-info h2::after {
  content: ''; /* Empty content for the pseudo-element */
  width: calc(25%); /* Adjust the width based on available space */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 1%;
}
/* Add margin-bottom to the h1 with the "follow-us" class */
.contact-info .follow-us {
    margin-top: 15%; /* Adjust the margin as needed */
}
/* Contacts.css */
.contact-info h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: black;
  
  text-align: right; /* Aligns the text to the right */
  position: relative; /* Add this to establish a positioning context */
}

.contact-info h3::after {
  content: ''; /* Empty content for the pseudo-element */
  width: calc(45%); /* Adjust the width based on available space */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 1%;
}

/* Contacts.css */
.contact-info h4 {
  font-size: 18px;
  margin-bottom: 10px;
  color: black;
  position: relative; 

  text-align: right; /* Aligns the text to the right */
}

.contact-info h4::after {
  content: ''; /* Empty content for the pseudo-element */
  width: calc(55%); /* Adjust the width based on available space */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 1%;
}


/* Contacts.css */
.contact-info h5 {
  font-size: 18px;
  margin-bottom: 10px;
  color: black;
  text-align: right;
  position: relative; /* Add this to establish a positioning context */
}

.contact-info h5::after {
  content: ''; /* Empty content for the pseudo-element */
  width: calc(73% ); /* Adjust the width based on available space */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 1%;
}

/* Contacts.css */
.contact-info h6 {
  font-size: 18px;
  margin-bottom: 10px;
  color: black;
  text-align: right;
  position: relative; /* Add this to establish a positioning context */
}

.contact-info h6::after {
  content: ''; /* Empty content for the pseudo-element */
  width: calc(78% ); /* Adjust the width based on available space */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 1%;
}
.fale{
  text-align: center;
}

.fale2{
  margin-top:18%;
  text-align: center;
}
.contact-info h61 {

  font-size: 28px;

}

}

@media screen and (orientation: portrait) and (max-width: 1023px) and (min-height:1800px) {
/* Contacts.css */
.contact-info {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  
}

/* Contacts.css */
.contact-info h1 {
  font-size: 28px;
  margin-bottom: 20px;
  margin-top: 10%;
  color: black;
  position: relative; /* Add this to establish a positioning context */
  text-align: left;
}

.contact-info h1::after {
  content: ''; /* Empty content for the pseudo-element */
  width: 67%; /* Adjust the width of the line as needed */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 40%; /* Position it at the beginning of the h1 element */
  margin-left:-7%;
}

/* Contacts.css */
.contact-info h2 {
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 3%;
  color: black;
  text-align: right; /* Aligns the text to the right */
  position: relative; /* Add this to establish a positioning context */
}

.contact-info h2::after {
  content: ''; /* Empty content for the pseudo-element */
  width: calc(42%); /* Adjust the width based on available space */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 1%;
}
/* Add margin-bottom to the h1 with the "follow-us" class */
.contact-info .follow-us {
    margin-top: 18%; /* Adjust the margin as needed */
}
/* Contacts.css */
.contact-info h3 {
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 3%;
  color: black;
  
  text-align: right; /* Aligns the text to the right */
  position: relative; /* Add this to establish a positioning context */
}

.contact-info h3::after {
  content: ''; /* Empty content for the pseudo-element */
  width: calc(55%); /* Adjust the width based on available space */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 1%;
}

/* Contacts.css */
.contact-info h4 {
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 3%;
  color: black;
  position: relative; 

  text-align: right; /* Aligns the text to the right */
}

.contact-info h4::after {
  content: ''; /* Empty content for the pseudo-element */
  width: calc(65%); /* Adjust the width based on available space */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 1%;
}


/* Contacts.css */
.contact-info h5 {
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 3%;
  color: black;
  text-align: right;
  position: relative; /* Add this to establish a positioning context */
}

.contact-info h5::after {
  content: ''; /* Empty content for the pseudo-element */
  width: calc(75% ); /* Adjust the width based on available space */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 1%;
}

/* Contacts.css */
.contact-info h6 {
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 3%;
  color: black;
  text-align: right;
  position: relative; /* Add this to establish a positioning context */
}

.contact-info h6::after {
  content: ''; /* Empty content for the pseudo-element */
  width: calc(81% ); /* Adjust the width based on available space */
  height: 4px; /* Adjust the height of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  bottom: 10px; /* Adjust the vertical position (below the text) */
  left: 1%;
}
.fale{
  text-align: center;
}

.fale2{
  margin-top:16%;
  text-align: center;
}
.contact-info h61 {

  font-size: 26px;

}
}


@media screen and (orientation: portrait) and (max-width: 1023px) and (max-height:1800px) {
  /* Contacts.css */
  .contact-info {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    
  }
  
  /* Contacts.css */
  .contact-info h1 {
    font-size: 28px;
    margin-bottom: 20px;
    margin-top: 10%;
    color: black;
    position: relative; /* Add this to establish a positioning context */
    text-align: left;
  }
  
  .contact-info h1::after {
    content: ''; /* Empty content for the pseudo-element */
    width: 67%; /* Adjust the width of the line as needed */
    height: 4px; /* Adjust the height of the line as needed */
    background-color: black; /* Change the color of the line as needed */
    position: absolute; /* Position the line absolutely */
    bottom: 10px; /* Adjust the vertical position (below the text) */
    left: 40%; /* Position it at the beginning of the h1 element */
    margin-left:-7%;
  }
  
  /* Contacts.css */
  .contact-info h2 {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 3%;
    color: black;
    text-align: right; /* Aligns the text to the right */
    position: relative; /* Add this to establish a positioning context */
  }
  
  .contact-info h2::after {
    content: ''; /* Empty content for the pseudo-element */
    width: calc(38%); /* Adjust the width based on available space */
    height: 4px; /* Adjust the height of the line as needed */
    background-color: black; /* Change the color of the line as needed */
    position: absolute; /* Position the line absolutely */
    bottom: 10px; /* Adjust the vertical position (below the text) */
    left: 1%;
  }
  /* Add margin-bottom to the h1 with the "follow-us" class */
  .contact-info .follow-us {
      margin-top: 18%; /* Adjust the margin as needed */
  }
  /* Contacts.css */
  .contact-info h3 {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 3%;
    color: black;
    
    text-align: right; /* Aligns the text to the right */
    position: relative; /* Add this to establish a positioning context */
  }
  
  .contact-info h3::after {
    content: ''; /* Empty content for the pseudo-element */
    width: calc(53%); /* Adjust the width based on available space */
    height: 4px; /* Adjust the height of the line as needed */
    background-color: black; /* Change the color of the line as needed */
    position: absolute; /* Position the line absolutely */
    bottom: 10px; /* Adjust the vertical position (below the text) */
    left: 1%;
  }
  
  /* Contacts.css */
  .contact-info h4 {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 3%;
    color: black;
    position: relative; 
  
    text-align: right; /* Aligns the text to the right */
  }
  
  .contact-info h4::after {
    content: ''; /* Empty content for the pseudo-element */
    width: calc(63%); /* Adjust the width based on available space */
    height: 4px; /* Adjust the height of the line as needed */
    background-color: black; /* Change the color of the line as needed */
    position: absolute; /* Position the line absolutely */
    bottom: 10px; /* Adjust the vertical position (below the text) */
    left: 1%;
  }
  
  
  /* Contacts.css */
  .contact-info h5 {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 3%;
    color: black;
    text-align: right;
    position: relative; /* Add this to establish a positioning context */
  }
  
  .contact-info h5::after {
    content: ''; /* Empty content for the pseudo-element */
    width: calc(75% ); /* Adjust the width based on available space */
    height: 4px; /* Adjust the height of the line as needed */
    background-color: black; /* Change the color of the line as needed */
    position: absolute; /* Position the line absolutely */
    bottom: 10px; /* Adjust the vertical position (below the text) */
    left: 1%;
  }
  
  /* Contacts.css */
  .contact-info h6 {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 3%;
    color: black;
    text-align: right;
    position: relative; /* Add this to establish a positioning context */
  }
  
  .contact-info h6::after {
    content: ''; /* Empty content for the pseudo-element */
    width: calc(81% ); /* Adjust the width based on available space */
    height: 4px; /* Adjust the height of the line as needed */
    background-color: black; /* Change the color of the line as needed */
    position: absolute; /* Position the line absolutely */
    bottom: 10px; /* Adjust the vertical position (below the text) */
    left: 1%;
  }
  .fale{
    text-align: center;
  }
  
  .fale2{
    margin-top:16%;
    text-align: center;
  }
  .contact-info h61 {
  
    font-size: 26px;
  
  }
  }

  @media screen and (orientation: landscape) and (max-width: 1023px)  and (max-height:400px){
    /* Contacts.css */
    .contact-info {
      width: 100%;
      margin: 0 auto;
      padding: 20px;
      
    }
    
    /* Contacts.css */
    .contact-info h1 {
      font-size: 28px;
      margin-bottom: 20px;
      margin-top: 2%;
      color: black;
      position: relative; /* Add this to establish a positioning context */
      text-align: left;
    }
    
    .contact-info h1::after {
      content: ''; /* Empty content for the pseudo-element */
      width: 67%; /* Adjust the width of the line as needed */
      height: 4px; /* Adjust the height of the line as needed */
      background-color: black; /* Change the color of the line as needed */
      position: absolute; /* Position the line absolutely */
      bottom: 10px; /* Adjust the vertical position (below the text) */
      left: 40%; /* Position it at the beginning of the h1 element */
      margin-left:-7%;
    }
    
    /* Contacts.css */
    .contact-info h2 {
      font-size: 20px;
      margin-bottom: 20px;
      margin-top: 0%;
      color: black;
      text-align: right; /* Aligns the text to the right */
      position: relative; /* Add this to establish a positioning context */
    }
    
    .contact-info h2::after {
      content: ''; /* Empty content for the pseudo-element */
      width: calc(38%); /* Adjust the width based on available space */
      height: 4px; /* Adjust the height of the line as needed */
      background-color: black; /* Change the color of the line as needed */
      position: absolute; /* Position the line absolutely */
      bottom: 10px; /* Adjust the vertical position (below the text) */
      left: 1%;
    }
    /* Add margin-bottom to the h1 with the "follow-us" class */
    .contact-info .follow-us {
        margin-top: 18%; /* Adjust the margin as needed */
    }
    /* Contacts.css */
    .contact-info h3 {
      font-size: 20px;
      margin-bottom: 20px;
      margin-top: 3%;
      color: black;
      
      text-align: right; /* Aligns the text to the right */
      position: relative; /* Add this to establish a positioning context */
    }
    
    .contact-info h3::after {
      content: ''; /* Empty content for the pseudo-element */
      width: calc(53%); /* Adjust the width based on available space */
      height: 4px; /* Adjust the height of the line as needed */
      background-color: black; /* Change the color of the line as needed */
      position: absolute; /* Position the line absolutely */
      bottom: 10px; /* Adjust the vertical position (below the text) */
      left: 1%;
    }
    
    /* Contacts.css */
    .contact-info h4 {
      font-size: 20px;
      margin-bottom: 20px;
      margin-top: 3%;
      color: black;
      position: relative; 
    
      text-align: right; /* Aligns the text to the right */
    }
    
    .contact-info h4::after {
      content: ''; /* Empty content for the pseudo-element */
      width: calc(63%); /* Adjust the width based on available space */
      height: 4px; /* Adjust the height of the line as needed */
      background-color: black; /* Change the color of the line as needed */
      position: absolute; /* Position the line absolutely */
      bottom: 10px; /* Adjust the vertical position (below the text) */
      left: 1%;
    }
    
    
    /* Contacts.css */
    .contact-info h5 {
      font-size: 20px;
      margin-bottom: 20px;
      margin-top: 3%;
      color: black;
      text-align: right;
      position: relative; /* Add this to establish a positioning context */
    }
    
    .contact-info h5::after {
      content: ''; /* Empty content for the pseudo-element */
      width: calc(75% ); /* Adjust the width based on available space */
      height: 4px; /* Adjust the height of the line as needed */
      background-color: black; /* Change the color of the line as needed */
      position: absolute; /* Position the line absolutely */
      bottom: 10px; /* Adjust the vertical position (below the text) */
      left: 1%;
    }
    
    /* Contacts.css */
    .contact-info h6 {
      font-size: 20px;
      margin-bottom: 20px;
      margin-top: 3%;
      color: black;
      text-align: right;
      position: relative; /* Add this to establish a positioning context */
    }
    
    .contact-info h6::after {
      content: ''; /* Empty content for the pseudo-element */
      width: calc(81% ); /* Adjust the width based on available space */
      height: 4px; /* Adjust the height of the line as needed */
      background-color: black; /* Change the color of the line as needed */
      position: absolute; /* Position the line absolutely */
      bottom: 10px; /* Adjust the vertical position (below the text) */
      left: 1%;
    }
    .fale{
      text-align: center;
    }
    
    .fale2{
      margin-top:16%;
      text-align: center;
    }
    .contact-info h61 {
    
      font-size: 26px;
    
    }
    }
    

    @media screen and (orientation: landscape) and (max-width: 1023px)  and (min-height:400px){
      /* Contacts.css */
      .contact-info {
        width: 100%;
        margin: 0 auto;
        padding: 20px;
        
      }
      
      /* Contacts.css */
      .contact-info h1 {
        font-size: 28px;
        margin-bottom: 20px;
        margin-top: 2%;
        color: black;
        position: relative; /* Add this to establish a positioning context */
        text-align: left;
      }
      
      .contact-info h1::after {
        content: ''; /* Empty content for the pseudo-element */
        width: 67%; /* Adjust the width of the line as needed */
        height: 4px; /* Adjust the height of the line as needed */
        background-color: black; /* Change the color of the line as needed */
        position: absolute; /* Position the line absolutely */
        bottom: 10px; /* Adjust the vertical position (below the text) */
        left: 40%; /* Position it at the beginning of the h1 element */
        margin-left:-7%;
      }
      
      /* Contacts.css */
      .contact-info h2 {
        font-size: 20px;
        margin-bottom: 20px;
        margin-top: 0%;
        color: black;
        text-align: right; /* Aligns the text to the right */
        position: relative; /* Add this to establish a positioning context */
      }
      
      .contact-info h2::after {
        content: ''; /* Empty content for the pseudo-element */
        width: calc(38%); /* Adjust the width based on available space */
        height: 4px; /* Adjust the height of the line as needed */
        background-color: black; /* Change the color of the line as needed */
        position: absolute; /* Position the line absolutely */
        bottom: 10px; /* Adjust the vertical position (below the text) */
        left: 1%;
      }
      /* Add margin-bottom to the h1 with the "follow-us" class */
      .contact-info .follow-us {
          margin-top: 18%; /* Adjust the margin as needed */
      }
      /* Contacts.css */
      .contact-info h3 {
        font-size: 20px;
        margin-bottom: 20px;
        margin-top: 3%;
        color: black;
        
        text-align: right; /* Aligns the text to the right */
        position: relative; /* Add this to establish a positioning context */
      }
      
      .contact-info h3::after {
        content: ''; /* Empty content for the pseudo-element */
        width: calc(53%); /* Adjust the width based on available space */
        height: 4px; /* Adjust the height of the line as needed */
        background-color: black; /* Change the color of the line as needed */
        position: absolute; /* Position the line absolutely */
        bottom: 10px; /* Adjust the vertical position (below the text) */
        left: 1%;
      }
      
      /* Contacts.css */
      .contact-info h4 {
        font-size: 20px;
        margin-bottom: 20px;
        margin-top: 3%;
        color: black;
        position: relative; 
      
        text-align: right; /* Aligns the text to the right */
      }
      
      .contact-info h4::after {
        content: ''; /* Empty content for the pseudo-element */
        width: calc(63%); /* Adjust the width based on available space */
        height: 4px; /* Adjust the height of the line as needed */
        background-color: black; /* Change the color of the line as needed */
        position: absolute; /* Position the line absolutely */
        bottom: 10px; /* Adjust the vertical position (below the text) */
        left: 1%;
      }
      
      
      /* Contacts.css */
      .contact-info h5 {
        font-size: 20px;
        margin-bottom: 20px;
        margin-top: 3%;
        color: black;
        text-align: right;
        position: relative; /* Add this to establish a positioning context */
      }
      
      .contact-info h5::after {
        content: ''; /* Empty content for the pseudo-element */
        width: calc(75% ); /* Adjust the width based on available space */
        height: 4px; /* Adjust the height of the line as needed */
        background-color: black; /* Change the color of the line as needed */
        position: absolute; /* Position the line absolutely */
        bottom: 10px; /* Adjust the vertical position (below the text) */
        left: 1%;
      }
      
      /* Contacts.css */
      .contact-info h6 {
        font-size: 20px;
        margin-bottom: 20px;
        margin-top: 3%;
        color: black;
        text-align: right;
        position: relative; /* Add this to establish a positioning context */
      }
      
      .contact-info h6::after {
        content: ''; /* Empty content for the pseudo-element */
        width: calc(81% ); /* Adjust the width based on available space */
        height: 4px; /* Adjust the height of the line as needed */
        background-color: black; /* Change the color of the line as needed */
        position: absolute; /* Position the line absolutely */
        bottom: 10px; /* Adjust the vertical position (below the text) */
        left: 1%;
      }
      .fale{
        text-align: center;
      }
      
      .fale2{
        margin-top:16%;
        text-align: center;
      }
      .contact-info h61 {
      
        font-size: 26px;
      
      }
      }
      
      