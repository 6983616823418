
.contactos_text_rr {
  margin-top: 3%;
}
.contactos_text_rr h2{
  margin-left:15%;
  margin-top: 16px;
  font-size: 34px;
  color: #333;
}
.contactos_text_rr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: auto;
  margin-left:6%;

}
.contactos_text_r h2::after {
  content: '';
  width: calc(60% );
  height: 6px;
  background-color: transparent;
  position: absolute;
  bottom: 10px;
  left: 34%;
  top:11.5vh;
}

.container_project_p{
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}
.contact_container_pub_p {
  margin-left: 1%;
  margin-top: 22.5%;
  padding-top:3%;
  width:12%;
  height:auto;
}

.contact_right-container_p {
width: 80%;
height:80vh;
margin-left: 21.5%;
padding-right: 8%;
padding-bottom: 0%;
overflow-y: scroll;
overflow-x: hidden;
justify-content: space-between;
display: flex;
justify-content: space-between; /* To create space between columns */
margin-top : -25%;
}
.tab-container {
  display: flex;
  justify-content: space-between;
  width:40%;
  margin-top:1%;
  margin-left:17%;
  z-index: 100;
}
.tab {
  position: relative;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: default;
  transition: border-color 0.1s ease;
  font-weight: bold;
  font-family: Futura;
  font-size: 35px;
  color: gray;
  padding-bottom: 10px; /* Create space for the line */
}

/* Add the line using pseudo-elements */
.tab::before {
  content: ''; /* Empty content for the pseudo-element */
  position: absolute;
  bottom: -2px; /* Position it at the bottom of the tab */
  left: 0; /* Align it to the left */
  width: 100%; /* Full width */
  height: 4px; /* Height of the line */
  background-color: transparent; /* Initially transparent */
  transition: background-color 0.3s ease; /* Transition effect */
}

/* Change the line color on hover */
.tab:hover::before {
  background-color: black; /* Change the line color on hover */
}
.active-tab {
  background-color: transparent;
  color: black;
  font-size: 35px;
  font-weight: bold;
  font-family: Futura;
}
.card-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* to ensure the image covers the entire space */
  /*margin-bottom:-1.2%;*/
}
/* CSS for text overlay animation (on hover) */
.project-card:hover .text-overlay {
  opacity: 1;
  background: transparent; /* Change the background color on hover */
   margin-top:-.8%;
   margin-left:-1.75%;
   height:auto;
}

.text-overlay h3,
.text-overlay .button-link {
  display: none;
}
.card-image-container:hover .text-overlay h3 {
  display: block;
  font-size: 25px;
  color:black;
  margin-top:-10%;
  margin-left:10%;
  width: 14%;
}
.card-image-container:hover .text-overlay .button-link {
  display: block;
  font-size: 18px;
  color:white;
  margin-top:16%;
  margin-left:88%;
}
.image-container {
  position: relative;
  display: inline-block;
  margin: 10px;
  /*overflow: hidden;*/
}
.image-container img {
  display: block;
  width: 100%;
  height: auto;
}
.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(1, 0, 0, 0.6);
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

/* CSS for text overlay animation (on hover) */
.project-card:hover .text-overlay {
  opacity: 1;
  background: transparent; /* Change the background color on hover */
   margin-top:-.8%;
   margin-left:-1.75%;
   height:auto;
}

.image-container:hover .text-overlay {
  opacity: 1;
}


.copyright_pro {
  text-align: center;
  color:black;
  flex-grow: 1; /* To center the text */
  margin-left: 20px; /* Adjust spacing between icons and copyright text */
  margin-top: .5%; /* Adjust spacing between icons and copyright text */
  font-size: 14px; /* Adjust the font size as needed */
  font-weight: bold;
}


/*------------------------------------------------------------------------------------------------------*/

@media screen and (orientation: portrait) and (max-width: 1023px) and (max-height:1800px) {

  .contactos_text_rr {
    margin-top: 7%; 
    padding-left: 7%;
  }
  .contactos_text_rr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: auto;
    margin-left:6%;
  
  }
  /*
  .contactos_text_rr h2{
    margin-left:35%;
    margin-top: 20px;
    font-size: 34px;
    color: #333;
  }
  .contactos_text_r h2::after {
    content: '';
    width: calc(60% );
    height: 6px;
    background-color: black;
    position: absolute;
    bottom: 10px; margin-top: 7%;
    left: 34%;
    top:11.5vh;
  }
  */
  .container_project_p{
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
  }
  .contact_container_pub_p {
    margin-left: 1%;
    margin-top: 22.5%;
    padding-top:3%;
    width:12%;
    height:auto;
  }
  
  .contact_right-container_p {
  width: 80%;
  height:78vh;
  margin-left: 34.5%;
  padding-right: 8%;
  padding-bottom: 0%;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: space-between;
  display: flex;
  justify-content: space-between; /* To create space between columns */
  margin-top : -25%;
  }
  .tab-container {
    display: flex;
    justify-content: space-between;
    width:70%;
    margin-top:1%;
    margin-left:17%;
    z-index: 100;
  }
  .tab {
    position: relative;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: default;
    transition: border-color 0.1s ease;
    font-weight: bold;
    font-family: Futura;
    font-size: 35px;
    color: gray;
    padding-bottom: 10px; /* Create space for the line */
  }
  
  /* Add the line using pseudo-elements */
  .tab::before {
    content: ''; /* Empty content for the pseudo-element */
    position: absolute;
    bottom: -2px; /* Position it at the bottom of the tab */
    left: 0; /* Align it to the left */
    width: 100%; /* Full width */
    height: 4px; /* Height of the line */
    background-color: transparent; /* Initially transparent */
    transition: background-color 0.3s ease; /* Transition effect */
  }
  
  /* Change the line color on hover */
  .tab:hover::before {
    background-color: black; /* Change the line color on hover */
  }
  .active-tab {
    background-color: transparent;
    color: black;
    font-size: 35px;
    font-weight: bold;
    font-family: Futura;
  }
  .card-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* to ensure the image covers the entire space */
    /*margin-bottom:-1.2%;*/
  }
  /* CSS for text overlay animation (on hover) */
  .project-card:hover .text-overlay {
    opacity: 1;
    background: transparent; /* Change the background color on hover */
     margin-top:-.8%;
     margin-left:-1.75%;
     height:auto;
  }
  
  .text-overlay h3,
  .text-overlay .button-link {
    display: none;
  }
  .card-image-container:hover .text-overlay h3 {
    display: block;
    font-size: 25px;
    color:white;
    margin-top:-10%;
    margin-left:10%;
  }
  .card-image-container:hover .text-overlay .button-link {
    display: block;
    font-size: 18px;
    color:white;
    margin-top:16%;
    margin-left:88%;
  }
  .image-container {
    position: relative;
    display: inline-block;
    margin: 10px;
    /*overflow: hidden;*/
  }
  .image-container img {
    display: block;
    width: 100%;
    height: auto;
  }
  .text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(1, 0, 0, 0.6);
    transition: opacity 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .image-container:hover .text-overlay {
    opacity: 1;
  }
  
  
  .copyright_pro {
    text-align: center;
    color:black;
    flex-grow: 1; /* To center the text */
    margin-left: 22px; /* Adjust spacing between icons and copyright text */
    margin-top: 1%; /* Adjust spacing between icons and copyright text */
    font-size: 18px; /* Adjust the font size as needed */
    font-weight: bold;
  }
}


@media screen and (orientation: portrait) and (max-width: 1023px) and (min-height:1800px) {

  .contactos_text_rr {
    margin-top: 7%; 
    padding-left: 7%;
  }
  .contactos_text_rr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: auto;
    margin-left:6%;
  
  }
  /*
  .contactos_text_rr h2{
    margin-left:35%;
    margin-top: 20px;
    font-size: 34px;
    color: #333;
  }
  .contactos_text_r h2::after {
    content: '';
    width: calc(60% );
    height: 6px;
    background-color: black;
    position: absolute;
    bottom: 10px; margin-top: 7%;
    left: 34%;
    top:11.5vh;
  }
  */
  .container_project_p{
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
  }
  .contact_container_pub_p {
    margin-left: 1%;
    margin-top: 22.5%;
    padding-top:3%;
    width:12%;
    height:auto;
  }
  
  .contact_right-container_p {
  width: 80%;
  height:82vh;
  margin-left: 34.5%;
  padding-right: 8%;
  padding-bottom: 0%;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: space-between;
  display: flex;
  justify-content: space-between; /* To create space between columns */
  margin-top : -25%;
  }
  .tab-container {
    display: flex;
    justify-content: space-between;
    width:70%;
    margin-top:1%;
    margin-left:17%;
    z-index: 100;
  }
  .tab {
    position: relative;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: default;
    transition: border-color 0.1s ease;
    font-weight: bold;
    font-family: Futura;
    font-size: 35px;
    color: gray;
    padding-bottom: 10px; /* Create space for the line */
  }
  
  /* Add the line using pseudo-elements */
  .tab::before {
    content: ''; /* Empty content for the pseudo-element */
    position: absolute;
    bottom: -2px; /* Position it at the bottom of the tab */
    left: 0; /* Align it to the left */
    width: 100%; /* Full width */
    height: 4px; /* Height of the line */
    background-color: transparent; /* Initially transparent */
    transition: background-color 0.3s ease; /* Transition effect */
  }
  
  /* Change the line color on hover */
  .tab:hover::before {
    background-color: black; /* Change the line color on hover */
  }
  .active-tab {
    background-color: transparent;
    color: black;
    font-size: 35px;
    font-weight: bold;
    font-family: Futura
  }
  .card-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* to ensure the image covers the entire space */
    /*margin-bottom:-1.2%;*/
  }
  /* CSS for text overlay animation (on hover) */
  .project-card:hover .text-overlay {
    opacity: 1;
    background: transparent; /* Change the background color on hover */
     margin-top:-.8%;
     margin-left:-1.75%;
     height:auto;
  }
  
  .text-overlay h3,
  .text-overlay .button-link {
    display: none;
  }
  .card-image-container:hover .text-overlay h3 {
    display: block;
    font-size: 25px;
    color:white;
    margin-top:-10%;
    margin-left:10%;
  }
  .card-image-container:hover .text-overlay .button-link {
    display: block;
    font-size: 18px;
    color:white;
    margin-top:16%;
    margin-left:88%;
  }
  .image-container {
    position: relative;
    display: inline-block;
    margin: 10px;
    /*overflow: hidden;*/
  }
  .image-container img {
    display: block;
    width: 100%;
    height: auto;
  }
  .text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(1, 0, 0, 0.6);
    transition: opacity 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .image-container:hover .text-overlay {
    opacity: 1;
  }
  
  
  .copyright_pro {
    text-align: center;
    color:black;
    flex-grow: 1; /* To center the text */
    margin-left: 22px; /* Adjust spacing between icons and copyright text */
    margin-top: 1%; /* Adjust spacing between icons and copyright text */
    font-size: 20px; /* Adjust the font size as needed */
    font-weight: bold;
  }
}


@media screen and (orientation: landscape) and (max-width: 1023px)  and (max-height:400px){

  .contactos_text_rr {
    margin-top: 3%; 
    padding-left: 7%;
  }
  .contactos_text_rr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: auto;
    margin-left:6%;
  
  }
  .container_project_p{
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
  }
  .contact_container_pub_p {
    margin-left: 1%;
    margin-top: 22.5%;
    padding-top:3%;
    width:12%;
    height:auto;
  }
  
  .contact_right-container_p {
  width: 80%;
  height:58vh;
  margin-left: 27%;
  padding-right: 8%;
  padding-bottom: 0%;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: space-between;
  display: flex;
  justify-content: space-between; /* To create space between columns */
  margin-top : -25%;
  }
  .tab-container {
    display: flex;
    justify-content: space-between;
    width:70%;
    margin-top:1%;
    margin-left:17%;
    z-index: 100;
  }
  .tab {
    position: relative;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: default;
    transition: border-color 0.1s ease;
    font-weight: bold;
    font-family: Futura;
    font-size: 20px;
    color: gray;
    padding-bottom: 10px; /* Create space for the line */
  }
  
  /* Add the line using pseudo-elements */
  .tab::before {
    content: ''; /* Empty content for the pseudo-element */
    position: absolute;
    bottom: -2px; /* Position it at the bottom of the tab */
    left: 0; /* Align it to the left */
    width: 100%; /* Full width */
    height: 4px; /* Height of the line */
    background-color: transparent; /* Initially transparent */
    transition: background-color 0.3s ease; /* Transition effect */
  }
  
  /* Change the line color on hover */
  .tab:hover::before {
    background-color: black; /* Change the line color on hover */
  }
  .active-tab {
    background-color: transparent;
    color: black;
    font-size: 20px;
    font-weight: bold;
    font-family: Futura
  }
  .card-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* to ensure the image covers the entire space */
    /*margin-bottom:-1.2%;*/
  }
  /* CSS for text overlay animation (on hover) */
  .project-card:hover .text-overlay {
    opacity: 1;
    background: transparent; /* Change the background color on hover */
     margin-top:-.8%;
     margin-left:-1.75%;
     height:auto;
  }
  
  .text-overlay h3,
  .text-overlay .button-link {
    display: none;
  }
  .card-image-container:hover .text-overlay h3 {
    display: block;
    font-size: 25px;
    color:white;
    margin-top:-10%;
    margin-left:10%;
  }
  .card-image-container:hover .text-overlay .button-link {
    display: block;
    font-size: 18px;
    color:white;
    margin-top:16%;
    margin-left:88%;
  }
  .image-container {
    position: relative;
    display: inline-block;
    margin: 10px;
    /*overflow: hidden;*/
  }
  .image-container img {
    display: block;
    width: 100%;
    height: auto;
  }
  .text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(1, 0, 0, 0.6);
    transition: opacity 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .image-container:hover .text-overlay {
    opacity: 1;
  }
  
  
  .copyright_pro {
    text-align: center;
    color:black;
    flex-grow: 1; /* To center the text */
    margin-left: 22px; /* Adjust spacing between icons and copyright text */
    margin-top: .2%; /* Adjust spacing between icons and copyright text */
    font-size: 14px; /* Adjust the font size as needed */
    font-weight: bold;
  }
}

@media screen and (orientation: landscape) and (max-width: 1023px)  and (min-height:400px){

  .contactos_text_rr {
    margin-top: 3%; 
    padding-left: 7%;
  }
  .contactos_text_rr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: auto;
    margin-left:6%;
  
  }
  .container_project_p{
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
  }
  .contact_container_pub_p {
    margin-left: 1%;
    margin-top: 22.5%;
    padding-top:3%;
    width:12%;
    height:auto;
  }
  
  .contact_right-container_p {
  width: 80%;
  height:68vh;
  margin-left: 27%;
  padding-right: 8%;
  padding-bottom: 0%;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: space-between;
  display: flex;
  justify-content: space-between; /* To create space between columns */
  margin-top : -25%;
  }
  .tab-container {
    display: flex;
    justify-content: space-between;
    width:70%;
    margin-top:1%;
    margin-left:17%;
    z-index: 100;
  }
  .tab {
    position: relative;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: default;
    transition: border-color 0.1s ease;
    font-weight: bold;
    font-family: Futura;
    font-size: 20px;
    color: gray;
    padding-bottom: 10px; /* Create space for the line */
  }
  
  /* Add the line using pseudo-elements */
  .tab::before {
    content: ''; /* Empty content for the pseudo-element */
    position: absolute;
    bottom: -2px; /* Position it at the bottom of the tab */
    left: 0; /* Align it to the left */
    width: 100%; /* Full width */
    height: 4px; /* Height of the line */
    background-color: transparent; /* Initially transparent */
    transition: background-color 0.3s ease; /* Transition effect */
  }
  
  /* Change the line color on hover */
  .tab:hover::before {
    background-color: black; /* Change the line color on hover */
  }
  .active-tab {
    background-color: transparent;
    color: black;
    font-size: 20px;
    font-weight: bold;
    font-family: Futura
  }
  .card-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* to ensure the image covers the entire space */
    /*margin-bottom:-1.2%;*/
  }
  /* CSS for text overlay animation (on hover) */
  .project-card:hover .text-overlay {
    opacity: 1;
    background: transparent; /* Change the background color on hover */
     margin-top:-.8%;
     margin-left:-1.75%;
     height:auto;
  }
  
  .text-overlay h3,
  .text-overlay .button-link {
    display: none;
  }
  .card-image-container:hover .text-overlay h3 {
    display: block;
    font-size: 25px;
    color:white;
    margin-top:-10%;
    margin-left:10%;
  }
  .card-image-container:hover .text-overlay .button-link {
    display: block;
    font-size: 18px;
    color:white;
    margin-top:16%;
    margin-left:88%;
  }
  .image-container {
    position: relative;
    display: inline-block;
    margin: 10px;
    /*overflow: hidden;*/
  }
  .image-container img {
    display: block;
    width: 100%;
    height: auto;
  }
  .text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(1, 0, 0, 0.6);
    transition: opacity 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .image-container:hover .text-overlay {
    opacity: 1;
  }
  
  
  .copyright_pro {
    text-align: center;
    color:black;
    flex-grow: 1; /* To center the text */
    margin-left: 22px; /* Adjust spacing between icons and copyright text */
    margin-top: .2%; /* Adjust spacing between icons and copyright text */
    font-size: 14px; /* Adjust the font size as needed */
    font-weight: bold;
  }
}

