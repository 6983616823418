.container_md {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height:  80vw;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  background-color: lightgray;
}


.projects_title_h11 {
  justify-content: space-between;
  align-items: center;
  width: 70%;
  font-size: 30px;
  margin-left:21%;
  padding-top:5%;
  font-family: Futura  !important;

}
.projects_price{

  justify-content: space-between;
  align-items: right;
  width: 30%;
  font-size: 30px;
  margin-left:86%;
  margin-top:-2.4%;
  color:red;
}
.projects_title_h11::after {
  content: '';
  width: calc(45.5%);
  height: 4px;
  background-color: transparent;
  position: absolute;
  top: 12vh;
  left:48%;
}
.slick-slider {
  width: 100%; /* Set Slider width to fill its parent */
  margin-top:1%;
  
 
}
.slick-slide {
  display: inline; 
  gap: 1rem;
}


.slick-slide .image {
  background-color: white;
  flex: none;
  margin: 4px;
  margin-top: 1%;
  padding: 1%;
  width:100%; /* Adjust the width for first and last images */
  height: auto;
}
.slick-slide .image img {
  width: 100%;
  height: calc(100vh * 9 / 16); /* 16:9 aspect ratio calculation */
  object-fit: cover; /* Maintain aspect ratio without stretching */

}
.slick-prev:before,
.slick-next:before {
  color: rgb(0, 0, 0); /* Set the color of the arrow icon */
  background: rgba(255, 255, 255, .5); /* Set the background color with opacity */
  font-size: 60px;
  border-radius: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px; /* Adjust width to match the size of the arrow */
  height: 60px; /* Adjust height to match the size of the arrow */
}

.custom-arrow {
  left: 82%;
  top: 47.9%;
  z-index: 100;
  cursor: pointer;
}
.custom-arrow-left {
  left: 16%;
  top: 48%;
  z-index: 100;
  cursor: pointer;
}


.buy-now-button {
  background-color: red; /* Change the background color to your preference */
  color: #fff; /* Text color */
  font-weight: bold;
  padding: 10px 10px; /* Adjust the padding as needed */
  border: none;
  border-radius: 0px;
  cursor: pointer;
  font-size: 16px; /* Adjust the font size as needed */
  margin-left:-50%  ;
  width:130%;
  margin-bottom: 0%;
  font-family: Futura  !important;
}
.buy-now-button:hover {
  background-color: #0056b3; /* Change the background color when hovered */
}


.buy-now-button_m {
  background-color: red; /* Change the background color to your preference */
  color: #fff; /* Text color */
  font-weight: bold;
  padding: 10px 10px; /* Adjust the padding as needed */
  border: none;
  border-radius: 0px;
  cursor: pointer;
  font-size: 16px; /* Adjust the font size as needed */
  margin-left:63vw  ;
  width:10vw;
  margin-bottom: 0%;
  font-family: Futura  !important;
}
.buy-now-button_m:hover {
  background-color: #0056b3; /* Change the background color when hovered */
}


.image-and-buybutton-container {
  display: flex;
  align-items: center;
  margin-top:-10%  ;
}
.info_house img {
    width:50%;
    height:auto;
    margin-left:45%;
}
.right-containerp {
  width: 74.7%;
  margin-left: 19.5%;
  margin-right: .8%;
  margin-top: 9%;
  justify-content: space-between;
}
.description-container {
  display: flex;
  width: 97%;
  margin-top:2%;
  margin-bottom:-8%;
  margin-left: 0%;
  margin-right: .8%;
  background-color: white;
  border:none;
  height:125vh;

}
.equipa-tecnica {
  background-color: transparent;
  display: flex;
  width: 30%;
  height:auto;
  flex-direction: column;
}

.image-containerpp {
  position: relative;
  width: 56%;
  height: 0;
  padding-bottom: 112.5%; /* 9:16 aspect ratio (16 / 9 * 100) */
  overflow: hidden;
  margin-left:18%;

  margin-bottom:18%;
}
.image-containerpp img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Keeps the image proportions without stretching */
  top: 0%;
  left: 0%;
}
.text-below-images {
  width: 73%;
  margin-left: 20%;
}
.text-below-images p {
  margin-left: 0%;
  font-size: 18px;
  color: #333;
  font-style: italic;
}
.team-title {
  font-size: 18px;
  font-weight: bold;  
  font-family: Futura  !important;
}
.role {
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  margin: 0;
  margin-top: 20px;
  font-family: Futura  !important;
}
.team-members {
  font-size: 16px;
  margin: 0;  
  font-family: Futura  !important;
}

.description_proj {
  background-color: transparent;
  width: 80%;
  height:auto;
  padding-left:1.5%;
  margin-right:-1.8%;
  font-family: Futura !important;
  text-align: justify;
}


.copyright_pro_de {
  text-align: center;
  color:black;
  flex-grow: 1; /* To center the text */
  margin-left: 20px; /* Adjust spacing between icons and copyright text */
  margin-top: 4.5%; /* Adjust spacing between icons and copyright text */
  font-size: 14px; /* Adjust the font size as needed */
  font-weight: bold;
}

.show-more-button {
  background-color:#E1E8E5;
  color: black;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  margin-left: 10px;
}

.show-more-button:hover {
  background-color: #E1E8E5;
}

.carousel-arrows {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.prev{
  background: transparent;
  border: none;
  font-size: 55px;
  cursor: pointer;
  color: white;
  padding: 5px 10%;
  z-index: 10000;
}

.next {
  background: transparent;
  border: none;
  font-size: 55px;
  cursor: pointer;
  color: white;
  padding: 5px 10%;
  z-index: 10000;
}

.prev:hover,
.next:hover {
  background-color: transparent;
}



@media screen and (min-width: 1440px) and (max-width:1800px) { 
  .copyright_pro_de {
  text-align: center;
  color:black;
  flex-grow: 1; /* To center the text */
  margin-left: 20px; /* Adjust spacing between icons and copyright text */
  margin-top: 4.5%; /* Adjust spacing between icons and copyright text */
  font-size: 14px; /* Adjust the font size as needed */
  font-weight: bold;
}

.description-container {
  display: flex;
  width: 97%;
  margin-top:2%;
  margin-bottom:-8%;
  margin-left: 0%;
  margin-right: .8%;
  background-color: white;
  border:none;
  height:205vh;

}

.slick-slider {
  width: 100%; /* Set Slider width to fill its parent */
  margin-top:1%;
  
 
}
.slick-slide {
  display: inline; 
  gap: 1rem;
}


.slick-slide .image {
  background-color: white;
  flex: none;
  margin: 4px;
  margin-top: 1%;
  padding: 1%;
  width:100%; /* Adjust the width for first and last images */
  height: auto;
}
.slick-slide .image img {
  width: 100%;
  height: calc(100vh * 9 / 16); /* 16:9 aspect ratio calculation */
  object-fit: cover; /* Maintain aspect ratio without stretching */

}
.slick-prev:before,
.slick-next:before {
  color: rgb(0, 0, 0); /* Set the color of the arrow icon */
  background: rgba(255, 255, 255, .5); /* Set the background color with opacity */
  font-size: 60px;
  border-radius: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px; /* Adjust width to match the size of the arrow */
  height: 60px; /* Adjust height to match the size of the arrow */
}

.custom-arrow {
  left: 82%;
  top: 47.9%;
  z-index: 100;
  cursor: pointer;
}
.custom-arrow-left {
  left: 16%;
  top: 48%;
  z-index: 100;
  cursor: pointer;
}
.projects_price{

  justify-content: space-between;
  align-items: right;
  width: 30%;
  font-size: 30px;
  margin-left:84.2%;
  margin-top:-3%;
  color:red;
}

.image-containerpp {
  position: relative;
  width: 56%;
  height: 0;
  padding-bottom: 112.5%; /* 9:16 aspect ratio (16 / 9 * 100) */
  overflow: hidden;
  margin-left:18%;

  margin-bottom:18%;
}
  .image-containerpp img {
    position: absolute;
    width: 100%;  
    top: 0%;
    left: 0%;
    height: 100%; /* Maintains aspect ratio */
    object-fit: cover; 
  }

}


@media screen and (orientation: portrait) and (max-width: 1023px) and (min-height:1800px) {

  .container_md {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height:  80vw;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    background-color: lightgray;
  }
  .projects_title_h11 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
      font-size: 30px;
    margin-top:3.5%;
    margin-left:28%;
    font-family: Futura  !important;
  
  }
  
  .projects_title_h11::after {
    content: '';
    width: calc(20.5%);
    height: 4px;
    background-color: transparent;
    position: absolute;
    top: 5.5%;
    left:77%;
  }
  
  .carousell_md {
    display: flex;
    min-width: 100%;
    margin-left: -30%;
    overflow-x: auto;
    gap: 1rem;
    scroll-snap-type: x mandatory;
  }
  .carousell_md::-webkit-scrollbar {
    display: none;
  }
   .carousell_md .item {
     background-color: white;
     flex: none;
     margin: 4px;
     margin-top: 3%;
     padding: 2px;
     width: 40%; /* Adjust the width for first and last images */
     height: auto;
   }
  
   .carousell_md .item .image img {
     width: 100%;
     height: 100%;
     object-fit: cover;
   }
  
  
  
  .buy-now-button {
    background-color: red; /* Change the background color to your preference */
    color: #fff; /* Text color */
    font-weight: bold;
    padding: 10px 10px; /* Adjust the padding as needed */
    border: none;
    border-radius: 0px;
    cursor: pointer;
    font-size: 16px; /* Adjust the font size as needed */
    margin-left:45%  ;
    width:130%;
    margin-bottom: 0%;
    font-family: Futura  !important;
  }
  .buy-now-button:hover {
    background-color: #0056b3; /* Change the background color when hovered */
  }

.buy-now-button_m {
  background-color: red; /* Change the background color to your preference */
  color: #fff; /* Text color */
  font-weight: bold;
  padding: 10px 10px; /* Adjust the padding as needed */
  border: none;
  border-radius: 0px;
  cursor: pointer;
  font-size: 16px; /* Adjust the font size as needed */
  margin-left:63vw  ;
  width:13vw;
  margin-bottom: 0%;
  font-family: Futura  !important;
}
.buy-now-button_m:hover {
  background-color: #0056b3; /* Change the background color when hovered */
}
  .image-and-buybutton-container {
    display: flex;
    align-items: center;
    margin-top:-10%  ;
  }
  .info_house img {
      width:45%;
      height:auto;
      margin-left:58%;
  }
  .right-containerp {
    width: 70%;
    margin-left: 20.5%;
    margin-right: .8%;
    margin-top: 9%;
    justify-content: space-between;
  }
  .description-container {
    display: flex;
    width: 98%;
    margin-top:2%;
    margin-bottom:-8%;
    margin-left: 10%;
    margin-right: .8%;
    background-color: white;
    border:none;
    height:150vh;
  
  }
  .equipa-tecnica {
    background-color: white;
    display: flex;
    width: 50%;
    height:63vh;
    flex-direction: column;
  }
  
  .image-containerpp {
    display: flex;
    justify-content: center; /* Align image horizontally at the center */
    align-items: center; /* Align image vertically at the center */
    width: 100%;
  }

.image-containerpp {
  position: relative;
  width: 56%;
  height: 0;
  padding-bottom: 112.5%; /* 9:16 aspect ratio (16 / 9 * 100) */
  overflow: hidden;
  margin-left:18%;

  margin-bottom:18%;
}
  .image-containerpp img {
    position: absolute;
    width: 100%;  
    top: 0%;
    left: 0%;
    height: 100%; /* Maintains aspect ratio */
    object-fit: cover; 
  }
  .text-below-images {
    width: 100%;
    margin-left: 0%;
    padding-top: 10%;
  }
  .text-below-images p {
    font-size: 18*px;
    color: black;
    font-style: italic;
  }
  .team-title {
    font-size: 18px;
    font-weight: bold;  
    font-family: Futura  !important;
    top:10%;

  }
  .role {
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
    margin: 0;
    margin-top: 20px;
    font-family: Futura  !important;
  }
  .team-members {
    font-size: 16px;
    margin: 0;  font-family: Futura;
  }
  
  .description_proj {
    background-color: transparent;
    width: 80%;
    height:auto;
    padding-left:1.5%;
    margin-right:-1.8%;
    font-family: Futura !important;
    text-align: justify;
  }
  
  
  .copyright_pro_de {
    text-align: center;
    color:black;
    flex-grow: 1; /* To center the text */
    margin-left: 20px; /* Adjust spacing between icons and copyright text */
    margin-top:-12 .5%; /* Adjust spacing between icons and copyright text */
    font-size: 14px; /* Adjust the font size as needed */
    font-weight: bold;
  }

.slick-slider {
  width: 100%; /* Set Slider width to fill its parent */
  margin-top:1%;
  
 
}
.slick-slide {
  display: inline; 
  gap: 1rem;
}


.slick-slide .image {
  background-color: white;
  flex: none;
  margin: 4px;
  margin-top: 1%;
  padding: 1%;
  width:100%; /* Adjust the width for first and last images */
  height: auto;
}
.slick-slide .image img {
  width: 100%;
  height: calc(30vh * 9 / 16); /* 16:9 aspect ratio calculation */
  object-fit: cover; /* Maintain aspect ratio without stretching */

}
.slick-prev:before,
.slick-next:before {
  color: rgb(0, 0, 0); /* Set the color of the arrow icon */
  background: rgba(255, 255, 255, .5); /* Set the background color with opacity */
  font-size: 60px;
  border-radius: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px; /* Adjust width to match the size of the arrow */
  height: 60px; /* Adjust height to match the size of the arrow */
}

.custom-arrow {
  left: 88%;
  top: 47.9%;
  z-index: 100;
  cursor: pointer;
}
.custom-arrow-left {
  left: 10%;
  top: 48%;
  z-index: 100;
  cursor: pointer;
}
.projects_price{

  justify-content: space-between;
  align-items: right;
  width: 30%;
  font-size: 30px;
  margin-left:83%;
  margin-top:-4.3%;
  color:red;
}

}


@media screen and (orientation: portrait) and (max-width: 1023px) and (max-height:1800px) {

  .container_md {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height:  80vw;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    background-color: lightgray;
  }
  .projects_title_h11 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
      font-size: 30px;
    margin-top:3.5%;
    margin-left:28%;
    font-family: Futura  !important;
  
  }
  
  .projects_title_h11::after {
    content: '';
    width: calc(20.5%);
    height: 4px;
    background-color: transparent;
    position: absolute;
    top: 6.5%;
    left:77%;
  }
  
  .carousell_md {
    display: flex;
    min-width: 100%;
    margin-left: -30%;
    overflow-x: auto;
    gap: 1rem;
    scroll-snap-type: x mandatory;
  }
  .carousell_md::-webkit-scrollbar {
    display: none;
  }
   .carousell_md .item {
     background-color: white;
     flex: none;
     margin: 4px;
     margin-top: 3%;
     padding: 2px;
     width: 40%; /* Adjust the width for first and last images */
     height: auto;
   }
  
  .buy-now-button {
    background-color: red; /* Change the background color to your preference */
    color: #fff; /* Text color */
    font-weight: bold;
    padding: 10px 10px; /* Adjust the padding as needed */
    border: none;
    border-radius: 0px;
    cursor: pointer;
    font-size: 16px; /* Adjust the font size as needed */
    margin-left:63vw  ;
    width:13vw;
    margin-bottom: 0%;
    font-family: Futura  !important;
  }
  .buy-now-button:hover {
    background-color: #0056b3; /* Change the background color when hovered */
  }
  .image-and-buybutton-container {
    display: flex;
    align-items: center;
    margin-top:-10%  ;
  }

.buy-now-button_m {
  background-color: red; /* Change the background color to your preference */
  color: #fff; /* Text color */
  font-weight: bold;
  padding: 10px 10px; /* Adjust the padding as needed */
  border: none;
  border-radius: 0px;
  cursor: pointer;
  font-size: 16px; /* Adjust the font size as needed */
  margin-left:63vw  ;
  width:10vw;
  margin-bottom: 0%;
  font-family: Futura  !important;
}
.buy-now-button_m:hover {
  background-color: #0056b3; /* Change the background color when hovered */
}
  .info_house img {
      width:45%;
      height:auto;
      margin-left:58%;
  }
  .right-containerp {
    width: 70%;
    margin-left: 20.5%;
    margin-right: .8%;
    margin-top: 9%;
    justify-content: space-between;
  }
  .description-container {
    display: flex;
    width: 98%;
    margin-top:2%;
    margin-bottom:-8%;
    margin-left: 10%;
    margin-right: .8%;
    background-color: white;
    border:none;
    height:125vh;
  
  }
  .equipa-tecnica {
    background-color: transparent;
    display: flex;
    width: 50%;
    height:auto;
    flex-direction: column;

  }
  
  .image-containerpp {
    display: flex;
    justify-content: center; /* Align image horizontally at the center */
    align-items: center; /* Align image vertically at the center */
  }
  .image-containerpp img {
    width: 100%; /* Ensures the image fills its container */
    /*max-width: 100%;*/ /* Keeps the image within its container */
    height: 45vh; /* Maintains aspect ratio */
    margin-left: 2%;
    margin-top: -2%; /* Adjust the top margin as needed */
  }


.image-containerpp {
  position: relative;
  width: 54%;
  height: 0;
  padding-bottom: 112.5%; /* 9:16 aspect ratio (16 / 9 * 100) */
  overflow: hidden;
  margin-left:18%;

  margin-bottom:18%;
}
  .image-containerpp img {
    position: absolute;
    width: 100%;  
    top: 0%;
    left: 0%;
    height: 100%; /* Maintains aspect ratio */
    object-fit: cover; 
  }

  .text-below-images {
    width: 73%;
    margin-left: 20%;
  }
  .text-below-images p {
    margin-left: 0%;
    font-size: 18px;
    color: #333;
    font-style: italic;
    font-family: Futura  !important;
  }
  .team-title {
    font-size: 18px;
    font-weight: bold;  
    font-family: Futura  !important;
  }
  .role {
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
    margin: 0;
    margin-top: 20px;
    font-family: Futura  !important;
  }
  .team-members {
    font-size: 16px;
    margin: 0;  font-family: Futura;
  }
  
  .description_proj {
    background-color: transparent;
    width: 80%;
    height:auto;
    padding-left:1.5%;
    margin-right:-1.8%;
    font-family: Futura !important;
    text-align: justify;
  }
  
  
  .copyright_pro_de {
    text-align: center;
    color:black;
    flex-grow: 1; /* To center the text */
    margin-left: 20px; /* Adjust spacing between icons and copyright text */
    margin-top: 95%; /* Adjust spacing between icons and copyright text */
    font-size: 14px; /* Adjust the font size as needed */
    font-weight: bold;
  }

.slick-slider {
  width: 100%; /* Set Slider width to fill its parent */
  margin-top:1%;
  
 
}
.slick-slide {
  display: inline; 
  gap: 1rem;
}


.slick-slide .image {
  background-color: white;
  flex: none;
  margin: 4px;
  margin-top: 1%;
  padding: 1%;
  width:100%; /* Adjust the width for first and last images */
  height: auto;
}
.slick-slide .image img {
  width: 100%;
  height: calc(30vh * 9 / 16); /* 16:9 aspect ratio calculation */
  object-fit: cover; /* Maintain aspect ratio without stretching */

}
.slick-prev:before,
.slick-next:before {
  color: rgb(0, 0, 0); /* Set the color of the arrow icon */
  background: rgba(255, 255, 255, .5); /* Set the background color with opacity */
  font-size: 60px;
  border-radius: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px; /* Adjust width to match the size of the arrow */
  height: 60px; /* Adjust height to match the size of the arrow */
}

.custom-arrow {
  left: 88%;
  top: 47.9%;
  z-index: 100;
  cursor: pointer;
}
.custom-arrow-left {
  left: 10%;
  top: 48%;
  z-index: 100;
  cursor: pointer;
}
.projects_price{

  justify-content: space-between;
  align-items: right;
  width: 30%;
  font-size: 30px;
  margin-left:83%;
  margin-top:-4.3%;
  color:red;
}
}


@media screen and (orientation: landscape) and (max-width: 1023px)  and (max-height:400px){

  .container_md {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height:  80vw;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    background-color: lightgray;
  }
  .projects_title_h11 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    font-size: 25px;
    margin-top:-1%;
    margin-left:18%;
    font-Family: Futura  !important;
  
  }
  
  .projects_title_h11::after {
    content: '';
    width: calc(45.5%);
    height: 4px;
    background-color: transparent;
    position: absolute;
    top: 15%;
    left:52%;
  }
  
  .carousell_md {
    display: flex;
    min-width: 100%;
    margin-left: -30%;
    overflow-x: auto;
    gap: 1rem;
    scroll-snap-type: x mandatory;
  }
  .carousell_md::-webkit-scrollbar {
    display: none;
  }
   .carousell_md .item {
     background-color: white;
     flex: none;
     margin: 4px;
     margin-top: 0%;
     padding: 2px;
     width: 40%; /* Adjust the width for first and last images */
     height: auto;
   }
  
   .carousell_md .item .image img {
     width: 100%;
     height: 100%;
     object-fit: cover;
   }
  
  
  
  .buy-now-button {
    background-color: red; /* Change the background color to your preference */
    color: #fff; /* Text color */
    font-weight: bold;
    padding: 10px 10px; /* Adjust the padding as needed */
    border: none;
    border-radius: 0px;
    cursor: pointer;
    font-size: 16px; /* Adjust the font size as needed */
    margin-left:45%  ;
    width:130%;
    margin-bottom: 0%;
    font-family: Futura  !important;
  }
  .buy-now-button:hover {
    background-color: #0056b3; /* Change the background color when hovered */
  }

.buy-now-button_m {
  background-color: red; /* Change the background color to your preference */
  color: #fff; /* Text color */
  font-weight: bold;
  padding: 10px 10px; /* Adjust the padding as needed */
  border: none;
  border-radius: 0px;
  cursor: pointer;
  font-size: 16px; /* Adjust the font size as needed */
  margin-left:63vw  ;
  width:14vw;
  margin-bottom: 0%;
  font-family: Futura  !important;
}
.buy-now-button_m:hover {
  background-color: #0056b3; /* Change the background color when hovered */
}
  .image-and-buybutton-container {
    display: flex;
    align-items: center;
    margin-top:-10%  ;
  }
  .info_house img {
      width:45%;
      height:auto;
      margin-left:58%;
  }
  .right-containerp {
    width: 70%;
    margin-left: 20.5%;
    margin-right: .8%;
    margin-top: 9%;
    justify-content: space-between;
  }
  .description-container {
    display: flex;
    width: 98%;
    margin-top:2%;
    margin-bottom:-8%;
    margin-left: 10%;
    margin-right: .8%;
    background-color: white;
    border:none;
    height:750vh;
  
  }
  .equipa-tecnica {
    background-color: transparent;
    display: flex;
    width: 50%;
    height:auto;
    flex-direction: column;
  }
  
  .image-containerpp {
    display: flex;
    justify-content: center; /* Align image horizontally at the center */
    align-items: center; /* Align image vertically at the center */
  }
  .image-containerpp img {
    width: 100%; /* Ensures the image fills its container */
    /*max-width: 100%;*/ /* Keeps the image within its container */
    height: 45vh; /* Maintains aspect ratio */
    margin-left: 0%;
    margin-top: -2%; /* Adjust the top margin as needed */
  }


.image-containerpp {
  position: relative;
  width: 54%;
  height: 0;
  padding-bottom: 112.5%; /* 9:16 aspect ratio (16 / 9 * 100) */
  overflow: hidden;
  margin-left:18%;

  margin-bottom:18%;
}
  .image-containerpp img {
    position: absolute;
    width: 100%;  
    top: 0%;
    left: 0%;
    height: 100%; /* Maintains aspect ratio */
    object-fit: cover; 
  }
  .text-below-images {
    width: 73%;
    margin-left: 20%;
  }
  .text-below-images p {
    margin-left: 0%;
    font-size: 18px;
    color: #333;
    font-style: italic;
  }
  .team-title {
    font-size: 18px;
    font-weight: bold;  
    font-family: Futura  !important;
  }
  .role {
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
    margin: 0;
    margin-top: 20px;
    font-family: Futura  !important;
  }
  .team-members {
    font-size: 16px;
    margin: 0;  font-family: Futura  !important;
  }
  
  .description_proj {
    background-color: transparent;
    width: 80%;
    height:auto;
    padding-left:1.5%;
    margin-right:-1.8%;
    font-family: Futura !important;
    text-align: justify;
  }
  
  
  .copyright_pro_de {
    text-align: center;
    color:black;
    margin-left: 0; /* Adjust spacing between icons and copyright text */
    margin-top: 0; /* Adjust spacing between icons and copyright text */
    font-size: 14px; /* Adjust the font size as needed */
    font-weight: bold;
  }


.slick-slider {
  width: 100%; /* Set Slider width to fill its parent */
  margin-top:-1%;
  
 
}
.slick-slide {
  display: inline; 
  gap: 1rem;
}


.slick-slide .image {
  background-color: white;
  flex: none;
  margin: 4px;
  margin-top: 1%;
  padding: 1%;
  width:100%; /* Adjust the width for first and last images */
  height: auto;
}
.slick-slide .image img {
  width: 100%;
  height: calc(90vh * 9 / 16); /* 16:9 aspect ratio calculation */
  object-fit: cover; /* Maintain aspect ratio without stretching */

}
.slick-prev:before,
.slick-next:before {
  color: rgb(0, 0, 0); /* Set the color of the arrow icon */
  background: rgba(255, 255, 255, .5); /* Set the background color with opacity */
  font-size: 60px;
  border-radius: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px; /* Adjust width to match the size of the arrow */
  height: 60px; /* Adjust height to match the size of the arrow */
}

.custom-arrow {
  left: 78%;
  top: 43.9%;
  z-index: 100;
  cursor: pointer;
}
.custom-arrow-left {
  left: 16.5%;
  top: 44%;
  z-index: 100;
  cursor: pointer;
}
.projects_price{

  justify-content: space-between;
  align-items: right;
  width: 30%;
  font-size: 30px;
  margin-left:84%;
  margin-top:-4.3%;
  color:red;
}

}



@media screen and (orientation: landscape) and (max-width: 1023px)  and (min-height:400px){

  .container_md {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height:  80vw;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    background-color: lightgray;
  }
  .projects_title_h11 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
      font-size: 25px;
    margin-top:-1%;
    margin-left:18%;
    font-Family: Futura  !important;
  
  }
  
  .projects_title_h11::after {
    content: '';
    width: calc(40.5%);
    height: 4px;
    background-color: transparent;
    position: absolute;
    top: 12%;
    left:55%;
  }
  
  .carousell_md {
    display: flex;
    min-width: 100%;
    margin-left: -30%;
    overflow-x: auto;
    gap: 1rem;
    scroll-snap-type: x mandatory;
  }
  .carousell_md::-webkit-scrollbar {
    display: none;
  }
   .carousell_md .item {
     background-color: white;
     flex: none;
     margin: 4px;
     margin-top: 0%;
     padding: 2px;
     width: 40%; /* Adjust the width for first and last images */
     height: auto;
   }
  
   .carousell_md .item .image img {
     width: 100%;
     height: 100%;
     object-fit: cover;
   }
  
  .buy-now-button {
    background-color: red; /* Change the background color to your preference */
    color: #fff; /* Text color */
    font-weight: bold;
    padding: 10px 10px; /* Adjust the padding as needed */
    border: none;
    border-radius: 0px;
    cursor: pointer;
    font-size: 16px; /* Adjust the font size as needed */
    margin-left:45%  ;
    width:130%;
    margin-bottom: 0%;
    font-family: Futura  !important;
  }
  .buy-now-button:hover {
    background-color: #0056b3; /* Change the background color when hovered */
  }

.buy-now-button_m {
  background-color: red; /* Change the background color to your preference */
  color: #fff; /* Text color */
  font-weight: bold;
  padding: 10px 10px; /* Adjust the padding as needed */
  border: none;
  border-radius: 0px;
  cursor: pointer;
  font-size: 16px; /* Adjust the font size as needed */
  margin-left:63vw  ;
  width:10vw;
  margin-bottom: 0%;
  font-family: Futura  !important;
}
.buy-now-button_m:hover {
  background-color: #0056b3; /* Change the background color when hovered */
}
  .image-and-buybutton-container {
    display: flex;
    align-items: center;
    margin-top:-10%  ;
  }
  .info_house img {
      width:45%;
      height:auto;
      margin-left:58%;
  }
  .right-containerp {
    width: 70%;
    margin-left: 20.5%;
    margin-right: .8%;
    margin-top: 9%;
    justify-content: space-between;
  }
  .description-container {
    display: flex;
    width: 98%;
    margin-top:2%;
    margin-bottom:-8%;
    margin-left: 10%;
    margin-right: .8%;
    background-color: white;
    border:none;
    height:570vh;
  
  }
  .equipa-tecnica {
    background-color: transparent;
    display: flex;
    width: 50%;
    height:auto;
    flex-direction: column;
  }
  
  .image-containerpp {
    display: flex;
    justify-content: center; /* Align image horizontally at the center */
    align-items: center; /* Align image vertically at the center */
  }
  .image-containerpp img {
    width: 100%; 
    height: 45vh; /* Maintains aspect ratio */
    margin-left: 0%;
    margin-top: -2%; /* Adjust the top margin as needed */
  }

.image-containerpp {
  position: relative;
  width: 54%;
  height: 0;
  padding-bottom: 112.5%; /* 9:16 aspect ratio (16 / 9 * 100) */
  overflow: hidden;
  margin-left:18%;

  margin-bottom:18%;
}
  .image-containerpp img {
    position: absolute;
    width: 100%;  
    top: 0%;
    left: 0%;
    height: 100%; /* Maintains aspect ratio */
    object-fit: cover; 
  }
  .text-below-images {
    width: 73%;
    margin-left: 20%;
  }
  .text-below-images p {
    margin-left: 0%;
    font-size: 18px;
    color: #333;
    font-style: italic;
  }
  .team-title {
    font-size: 18px;
    font-weight: bold;  
    font-family: Futura !important;
  }
  .role {
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
    margin: 0;
    margin-top: 20px;
    font-family: Futura  !important;
  }
  .team-members {
    font-size: 16px;
    margin: 0;  font-family: Futura  !important;
  }
  
  .description_proj {
    background-color: transparent;
    width: 80%;
    height:auto;
    padding-left:1.5%;
    margin-right:-1.8%;
    font-family: Futura !important;
    text-align: justify;
  }
  
  
  .copyright_pro_de {
    text-align: center;
    color:black;
    margin-left: 0; /* Adjust spacing between icons and copyright text */
    margin-top: -2%; /* Adjust spacing between icons and copyright text */
    font-size: 14px; /* Adjust the font size as needed */
    font-weight: bold;
  }


.slick-slider {
  width: 100%; /* Set Slider width to fill its parent */
  margin-top:0%;
  
 
}
.slick-slide {
  display: inline; 
  gap: 1rem;
}


.slick-slide .image {
  background-color: white;
  flex: none;
  margin: 4px;
  margin-top: 1%;
  padding: 1%;
  width:100%; /* Adjust the width for first and last images */
  height: auto;
}
.slick-slide .image img {
  width: 100%;
  height: calc(80vh * 9 / 16); /* 16:9 aspect ratio calculation */
  object-fit: cover; /* Maintain aspect ratio without stretching */

}
.slick-prev:before,
.slick-next:before {
  color: rgb(0, 0, 0); /* Set the color of the arrow icon */
  background: rgba(255, 255, 255, .5); /* Set the background color with opacity */
  font-size: 60px;
  border-radius: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px; /* Adjust width to match the size of the arrow */
  height: 60px; /* Adjust height to match the size of the arrow */
}

.custom-arrow {
  left: 88%;
  top: 43.9%;
  z-index: 100;
  cursor: pointer;
}
.custom-arrow-left {
  left: 10%;
  top: 44%;
  z-index: 100;
  cursor: pointer;
}
.projects_price{

  justify-content: space-between;
  align-items: right;
  width: 30%;
  font-size: 30px;
  margin-left:84%;
  margin-top:-4.3%;
  color:red;
}

}