.decreto-info {
    width: 70%;
    margin: 0 auto;
    margin-top:2%;
    padding: 5%;
    font-family: futura;
    font-size: 23px;
  }

  .copyright_decreto {
    text-align: center;
    height: 5%;
    color:black;
    flex-grow: 1; /* To center the text */
    margin-left: 20px; /* Adjust spacing between icons and copyright text */
    font-size: 1.25vh; /* Adjust the font size as needed */
     margin-top: 31.25%;
    font-weight: bold;
  }

  /* Styles for the container of the document list */
  .documents-list-decreto{
    display: flex;
    flex-direction: column;
    align-items: start; /* Align items to the start of the container for a neat list */
    padding-top: 30px; /* Add some padding around the whole list for spacing */
    background-color: white; /* Light background color for the list */
    border-radius: 8px; /* Optional: adds rounded corners for a modern look */
    max-width: 1200px; /* Adjust based on your layout needs */
    margin-top: -12%; /* Adjust to position the list as needed */
  }
  
  
  