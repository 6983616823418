/* Reset default margin and padding for body and HTML */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

/* Style the container for the image carousel */
.home {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

/* Base styles for carousel */
.carousel {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
}

/* Style the individual images */
.carousel-item img {
  object-fit: cover;
  width: 100%;
  height: 100vh;
}

.carousel-indicators {
  display: none; /* Hide the indicators */
}

.overlay-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 15%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

/* Ensure cursor pointer for the clickable element */
.carousel-item  {
  cursor: pointer; z-index: 12;
}


@media screen and (orientation: portrait) and (max-width: 1023px) and (max-height:1800px) {

  .overlay-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    height: 100%;
    background-color: red(0, 0, 0, 0.5);
    z-index: 2;
  }
}



@media screen and (orientation: portrait) and (max-width: 1023px) and (min-height:1800px) {

  .overlay-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    height: 100%;
    background-color: red(0, 0, 0, 0.5);
    z-index: 2;
  }
}

