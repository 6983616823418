.minuta-info {
  width: 70%;
  margin: 0 auto;
  margin-top:5%;
  padding: 5%;
  font-family: futura;
  font-size: 23px;
}

.copyright_munita {
  text-align: center;
  height: 5%;
  color:black;
  flex-grow: 1; /* To center the text */
  margin-left: 20px; /* Adjust spacing between icons and copyright text */
  font-size: 1.25vh; /* Adjust the font size as needed */
  margin-top: 1.25%;
  font-weight: bold;
}

.document-link a:hover span {
  color: #3498db; /* Change this to your desired hover color */
}
.document-link {
  margin-bottom: 10px; /* Adjust the space as needed */
}
.source_munita {
    text-align: center;
    height: 5%;
    color:red;
    flex-grow: 1; /* To center the text */
    margin-left: 20px; /* Adjust spacing between icons and copyright text */
    font-size: 1.25vh; /* Adjust the font size as needed */
    margin-top: 5.25%;
    font-weight: bold;
  }

  /* Styles for the container of the document list */
.documents-list {
  display: flex;
  flex-direction: column;
  align-items: start; /* Align items to the start of the container for a neat list */
  padding: 20px; /* Add some padding around the whole list for spacing */
  background-color: #f7f7f7; /* Light background color for the list */
  border-radius: 8px; /* Optional: adds rounded corners for a modern look */
  max-width: 900px; /* Adjust based on your layout needs */
  margin-top: -90px; /* Center the list if it's the main content */
}

/* Styles for each document link container */
.document-link {
  margin-bottom: 15px; /* Space between each document link */
  transition: transform 0.2s ease; /* Smooth transition for hover effect */
}

/* Hover effect for each document link to slightly lift off */
.document-link:hover {
  transform: translateY(-5px); /* Lift the link up on hover */
  cursor: pointer;
}

/* Style for the document name and icon span */
.document-link a {
  text-decoration: none; /* Remove underline from links */
  color: #333; /* Dark text for readability */
  font-size: 23px; /* Adjust based on your design */
}

/* Hover effect for the link text */
.document-link a:hover {
  color: #0056b3; /* Change text color on hover for visual feedback */
}

/* Style for the Microsoft Word icon */
.document-link span[role="img"] {
  margin-left: 10px; /* Space between text and icon */
}

/* ---------------------------------------------------------------  */

.minuta-info {
  width: 70%;
  margin: 0 auto;
  margin-top: 5%;
  padding: 5%;
  font-family: futura;
  font-size: 23px;
}

.source_munita {
  text-align: center;
  height: 5%;
  color: red;
  flex-grow: 1; /* To center the text */
  margin-left: 20px; /* Adjust spacing between icons and copyright text */
  font-size: 1.25vh; /* Adjust the font size as needed */
  margin-top: 1.25%;
  font-weight: bold;
}

.copyright_munita {
  text-align: center;
  height: 5%;
  color: black;
  flex-grow: 1; /* To center the text */
  margin-left: 20px; /* Adjust spacing between icons and copyright text */
  font-size: 1.25vh; /* Adjust the font size as needed */
  margin-top: -1.25%;
  font-weight: bold;
}

.document-link a:hover span {
  color: #3498db; /* Change this to your desired hover color */
}

.document-link {
  margin-bottom: 12px; /* Adjust the space as needed */
}

/* Styles for the container of the document list */
.documents-list {
  display: flex;
  flex-direction: column;
  align-items: start; /* Align items to the start of the container for a neat list */
  padding-top: 30px; /* Add some padding around the whole list for spacing */
  background-color: white; /* Light background color for the list */
  border-radius: 8px; /* Optional: adds rounded corners for a modern look */
  max-width: 900px; /* Adjust based on your layout needs */
  margin-top: -12%; /* Adjust to position the list as needed */
}

/* Additional styles follow... */






  
  
  