/* Logo.css */
.logo {
 position: absolute;
  left: 0.0vw;
  text-align: left; /* Center the content horizontally */
  margin-top: -0.5vw; /* Add margin for spacing from the top */
  margin-left: .5vw; /* Add margin for spacing from the top */
  z-index: 1000000; /* Ensure the Navbar is above the home carousel */
  max-width:10vw;
  height:auto;
  background-color:transparent;

   object-fit: cover;
  /* Your other Navbar styles */
}

.logo a {
  display: inline-block; /* Display the anchor element as a block to contain the image */
  text-decoration: none; /* Remove underlines from the link */

}

/* Style for the image */
.logo a img {
  margin-left: .5vw;
  max-width: 13vw; /* Make the image responsive */
  height: auto; /* Maintain the aspect ratio */
  /* Add other styles such as padding, border, etc. */

}


@media screen and (orientation: portrait) and (max-width: 1023px) {

/* Logo.css */
.logo {
  position: absolute;
   left: 0.0vw;
   text-align: left; /* Center the content horizontally */
   margin-top: -0.5vw; /* Add margin for spacing from the top */
   margin-left: .5vw; /* Add margin for spacing from the top */
   z-index: 10; /* Ensure the Navbar is above the home carousel */
   max-width:10vw;
   height:auto;
   background-color:transparent;
 
    object-fit: cover;
   /* Your other Navbar styles */
 }
 
 .logo a {
   display: inline-block; /* Display the anchor element as a block to contain the image */
   text-decoration: none; /* Remove underlines from the link */
 
 }
 
 /* Style for the image */
 .logo a img {
   margin-left: .5vw;
   max-width: 22vw; /* Make the image responsive */
   height: auto; /* Maintain the aspect ratio */
   /* Add other styles such as padding, border, etc. */
 
 }
 

}
