.container_reserva{
  height: 99vh;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

.reserva_text {
  margin-top: 4%;
}

.reserva_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  height: auto;
  margin-left:10%;

}
.reserva_text h2{
  margin-left:15%;
  margin-top: 16px;
  font-size: 32px;
  color: #333;
}
.reserva_text h2::after {
  content: '';
  width: calc(60% );
  height: 4px;
  background-color: transparent;
  position: absolute;
  bottom: 10px;
  left: 34%;
  top:12%;
}
.reserva_container_pub {
  margin-left: 1%;
  margin-top: 26%;
  padding-top:1%;
  width:12%;
  height:auto;
}
.reserva_right-container {
  background-color: #E1E8E5;
  width: 70%;
  height: 65vh;
  margin-left: 21.5%;
  padding-right: 2%;
  padding-bottom: 0%;
  overflow-y: hidden;
  overflow-x: hidden;
  justify-content: space-between;
  display: flex;
  justify-content: space-between; /* To create space between columns */
  margin-top : -25%;

  z-index: 102;
  }

.reserva_p1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
   font-weight: bold;
   font-family: Futura;
   margin-top:15%;
   font-size: 24px;
}

.reserva_p11{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
   font-weight: bold;
   font-family: Futura;
   margin-top:-.5vw;
   font-size: 24px;
}

/* Styles for the right column */
.rright-column-a {
  width: 60%;
  margin-top:0%;
  margin-left:2%;
  margin-right:0%;
  background-color: #E1E8E5;
}
/* Styles for the left column */
.reserva_left-column-a {
  background-color: white;
  margin-right:-3%;
  margin-left:3%;
}

.reserva_left-column-a img {
  width: 100%;
}



.copyright_r {
  text-align: center;
  color:black;
  flex-grow: 1; /* To center the text */
  margin-left: 20px; /* Adjust spacing between icons and copyright text */
  font-size: 12px; /* Adjust the font size as needed */
   margin-top: 5.5%;
  font-weight: bold;
}


@media screen and (orientation: portrait) and (max-width: 1023px) and (min-height:1800px) {
  .container_reserva{
    height: 99vh;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
  }
  
  .reserva_text {
    margin-top: 4%;
  }
  
  .reserva_text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: auto;
    margin-left:10%;
  
  }
  .reserva_text h2{
    margin-left:25%;
    margin-top: 7%;
    font-size: 32px;
    color: #333;
  }
  .reserva_text h2::after {
    content: '';
    width: calc(55% );
    height: 4px;
    background-color: transparent;
    position: absolute;
    bottom: 10px;
    left: 43%;
    top:6%;
  }
  .reserva_container_pub {
    margin-left: 1%;
    margin-top: 26%;
    padding-top:1%;
    width:12%;
    height:auto;
  }
  .reserva_right-container {
    background-color: #E1E8E5;
    width: 65%;
    height: 80vh;
    margin-left: 32.5%;
    padding-right: 2%;
    padding-bottom: 0%;
    overflow-y: hidden;
    overflow-x: hidden;
    justify-content: space-between;
    display: grid;
    justify-content: space-between; /* To create space between columns */
    margin-top : -22%;
  
    z-index: 102;
    }
  
  .reserva_p1{
    display: flex;
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     font-weight: bold;
     font-family: Futura;
     margin-top:15%;
     margin-left:50%;
     font-size: 32px;
  }
  
  .reserva_p11{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     font-weight: bold;
     font-family: Futura;
     margin-top:-.5vw;
     font-size: 24px;
  }
  
  /* Styles for the right column */
  .rright-column-a {
    width: 50%;
    margin-top:0%;
    margin-left:8%;
    background-color: #E1E8E5;
  }
  /* Styles for the left column */
  .reserva_left-column-a {
    background-color: #E1E8E5;
  }
  
  .reserva_left-column-a img {
    width: 100%;
  }
  
  .copyright_r {
    text-align: center;
    color:black;
    flex-grow: 1; /* To center the text */
    margin-left: 20px; /* Adjust spacing between icons and copyright text */
    font-size: 12px; /* Adjust the font size as needed */
     margin-top: 3.5%;
    font-weight: bold;
  }
  
}



@media screen and (orientation: portrait) and (max-width: 1023px) and (max-height:1800px) {
  .container_reserva{
    height: 99vh;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
  }
  
  .reserva_text {
    margin-top: 4%;
  }
  
  .reserva_text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: auto;
    margin-left:10%;
  
  }
  .reserva_text h2{
    margin-left:25%;
    margin-top: 7%;
    font-size: 32px;
    color: #333;
  }
  .reserva_text h2::after {
    content: '';
    width: calc(55% );
    height: 4px;
    background-color: black;
    position: absolute;
    bottom: 10px;
    left: 43%;
    top:6%;
  }
  .reserva_container_pub {
    margin-left: 1%;
    margin-top: 26%;
    padding-top:1%;
    width:12%;
    height:auto;
  }
  .reserva_right-container {
    background-color: #E1E8E5;
    width: 65%;
    height: 80vh;
    margin-left: 32.5%;
    padding-right: 2%;
    padding-bottom: 0%;
    overflow-y: hidden;
    overflow-x: hidden;
    justify-content: space-between;
    display: grid;
    justify-content: space-between; /* To create space between columns */
    margin-top : -22%;
  
    z-index: 102;
    }
  
  .reserva_p1{
    display: flex;
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     font-weight: bold;
     font-family: Futura;
     margin-top:15%;
     margin-left:50%;
     font-size: 32px;
  }
  
  .reserva_p11{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     font-weight: bold;
     font-family: Futura;
     margin-top:-.5vw;
     font-size: 24px;
  }
  
  /* Styles for the right column */
  .rright-column-a {
    width: 50%;
    margin-top:0%;
    margin-left:8%;
    background-color: #E1E8E5;
  }
  /* Styles for the left column */
  .reserva_left-column-a{
    background-color: #E1E8E5;
  }
  
  .reserva_left-column-a img {
    width: 100%;
  }
  
  .copyright_r {
    text-align: center;
    color:black;
    flex-grow: 1; /* To center the text */
    margin-left: 20px; /* Adjust spacing between icons and copyright text */
    font-size: 12px; /* Adjust the font size as needed */
     margin-top: 3.5%;
    font-weight: bold;
  }
  
}


@media screen and (orientation: landscape) and (max-width: 1023px)  and (min-height:400px){
  .container_reserva{
    height: 99vh;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
  }
  
  .reserva_text {
    margin-top: 4%;
  }
  
  .reserva_text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: auto;
    margin-left:10%;
  
  }
  .reserva_text h2{
    margin-left:20%;
    margin-top: 0%;
    font-size: 26px;
    color: #333;
  }
  .reserva_text h2::after {
    content: '';
    width: calc(59% );
    height: 4px;
    background-color: transparent;
    position: absolute;
    bottom: 10px;
    left: 39%;
    top:11%;
  }
  .reserva_container_pub {
    margin-left: 1%;
    margin-top: 26%;
    padding-top:1%;
    width:12%;
    height:auto;
  }
  .reserva_right-container {
    background-color: #E1E8E5;
    width: 65%;
    height: 68vh;
    margin-left: 32.5%;
    padding-right: 2%;
    padding-bottom: 0%;
    overflow-y: hidden;
    overflow-x: hidden;
    justify-content: space-between;
    display: flex;
    justify-content: space-between; /* To create space between columns */
    margin-top : -26%;
    z-index: 102;
    }
  
  .reserva_p1{
    display: flex;
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     font-weight: bold;
     font-family: Futura;
     margin-top:5%;
     margin-left:10%;
     font-size: 18px;
  }
  
  .reserva_p11{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     font-weight: bold;
     font-family: Futura;
     margin-top:-.5vw;
     font-size: 22px;
  }
  
  /* Styles for the right column */
  .rright-column-a {
    width: 50%;
    margin-top:-1%;
    margin-left:4%;
    background-color: #E1E8E5;
  }
  /* Styles for the left column */
  .reserva_left-column-a {
    background-color: #E1E8E5;
    overflow: hidden; /* Hide overflow content */
  }
  .reserva_left-column-a img {
    width: 100%;
    height: 100%;
  }
  
  .copyright_r {
    text-align: center;
    color:black;
    flex-grow: 1; /* To center the text */
    margin-left: 20px; /* Adjust spacing between icons and copyright text */
    font-size: 12px; /* Adjust the font size as needed */
     margin-top: 1.2%;
    font-weight: bold;
  }

}



@media screen and (orientation: landscape) and (max-width: 1023px)  and (max-height:400px){
  .container_reserva{
    height: 99vh;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
  }
  
  .reserva_text {
    margin-top: 4%;
  }
  
  .reserva_text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: auto;
    margin-left:10%;
  
  }
  .reserva_text h2{
    margin-left:20%;
    margin-top: 0%;
    font-size: 26px;
    color: #333;
  }
  .reserva_text h2::after {
    content: '';
    width: calc(59% );
    height: 4px;
    background-color: transparent;
    position: absolute;
    bottom: 10px;
    left: 39%;
    top:14.5%;
  }
  .reserva_container_pub {
    margin-left: 1%;
    margin-top: 26%;
    padding-top:1%;
    width:12%;
    height:auto;
  }
  .reserva_right-container {
    background-color: #E1E8E5;
    width: 65%;
    height: 68vh;
    margin-left: 32.5%;
    padding-right: 2%;
    padding-bottom: 0%;
    overflow-y: hidden;
    overflow-x: hidden;
    justify-content: space-between;
    display: flex;
    justify-content: space-between; /* To create space between columns */
    margin-top : -26%;
    z-index: 102;
    }
  
  .reserva_p1{
    display: flex;
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     font-weight: bold;
     font-family: Futura;
     margin-top:5%;
     margin-left:10%;
     font-size: 18px;
  }
  
  .reserva_p11{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     font-weight: bold;
     font-family: Futura;
     margin-top:-.5vw;
     font-size: 22px;
  }
  
  /* Styles for the right column */
  .rright-column-a {
    width: 50%;
    margin-top:-1%;
    margin-left:4%;
    background-color: #E1E8E5;
  }
  /* Styles for the left column */
  .reserva_left-column-a {
    background-color: #E1E8E5;
    height: 60vh;
    overflow: hidden; /* Hide overflow content */
  }
  .reserva_left-column-a img {
    width: 100%;
    height: 100%;
  }
  
  .copyright_r {
    text-align: center;
    color:black;
    flex-grow: 1; /* To center the text */
    margin-left: 20px; /* Adjust spacing between icons and copyright text */
    font-size: 12px; /* Adjust the font size as needed */
     margin-top: 1.2%;
    font-weight: bold;
  }

}


