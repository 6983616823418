/* Style for Navbar */
.navbar_plataforma {
  position: absolute;
  top: 47%;
  left: 1.5%;
  width: var(--adjusted-width, 10%);
  height: var(--adjusted-height, 10%);
  z-index: 15;
}
.navbar_plataforma.active {
    transform: translateX(2px); /* Slide to the right */
    transition: transform 1s ease, left 1s ease; /* Add transition for left */
    left: 11%;
}
/* Navbar.css */
.nav-link_plataforma {
  margin-right: 20px; /* Adjust the spacing as needed */
  text-decoration: none;
  color: black;
  font-size: 3vh;
}
/* Add other styles for links as needed */
.nav-menu_plataforma.active {
  transform: translateX(0px); /* Slide in from the left */
  transition: transform 0.3s ease;
  left:0;
}
.menu-bars_plataforma {
  margin-left:0.75vh;
  font-size: 2.6vh;
  background: none;
  color: white;
}
.nav-menu_plataforma {
  background-color: transparent;
  width: calc(15%);
  display: flex;
  justify-content: center;
  
  position: absolute;
  padding-top: 0%;
  left: -100%;
  transition: 850ms;
  z-index: 2;
  margin-left: -1%;
  margin-top: 0%;
}

.nav-text_plataforma {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  height: 4vh;
  padding-left:-200.5%;
  padding-top:22%;
}
.nav-text_plataforma a {
  text-decoration: none;
  color: white  !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 2vh;
  font-weight: bold;
  position: relative; /* Add this to establish a positioning context */
}
.nav-text_plataforma a::before {
  content: ''; /* Empty content for the pseudo-element */
  width: 35%; /* Adjust the width of the line as needed */
  height: .5vh; /* Adjust the height of the line as needed */
  margin-left: 7%;
  margin-top:15.5%;
  background-color: white  !important; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  transform: scaleX(0); /* Initially, set the line width to 0 */
  transform-origin: left; /* Adjust the transform origin to right */
  transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1); /* Transition for hover effect */
}
.nav-text_plataforma a:hover::before {
  transform: scaleX(1); 
  transform-origin: left;
}
.nav-menu-items_plataforma {
  width: 100%;
}
span {
  margin-left: 2vh;
}
.protected-item_plataforma {
  text-decoration: none;
  color: white  !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 2vh;
  margin-left:-1%;
  font-weight: bold;
  position: relative; /* Add this to establish a positioning context */
  cursor: not-allowed; /* Change cursor to indicate non-interactivity */
  /* You can add other styles like opacity, different background, etc. */
}


@media screen and (orientation: portrait) and (max-width: 1023px) and (max-height:1800px) {
/* Style for Navbar */
.navbar_plataforma {
  position: absolute;
  top: 38%;
  left: 1.5%;
  width: var(--adjusted-width, 10%);
  height: var(--adjusted-height, 10%);
  z-index: 15;
}
.navbar_plataforma.active {
    transform: translateX(2px); /* Slide to the right */
    transition: transform 1s ease, left 1s ease; /* Add transition for left */
    left: 31%;
}
/* Navbar.css */
.nav-link_plataforma {
  margin-right: 20px; /* Adjust the spacing as needed */
  text-decoration: none;
  color: black;
  font-size: 3vh;
}
/* Add other styles for links as needed */
.nav-menu_plataforma.active {
  transform: translateX(0px); /* Slide in from the left */
  transition: transform 0.3s ease;
  left:0;
}
.menu-bars_plataforma {
  margin-left:0.75vh;
  font-size: 2.6vh;
  background: none;
  color: white;
}
.nav-menu_plataforma {
  background-color: transparent;
  width: calc(50%);
  display: flex;
  justify-content: center;
  
  position: absolute;
  padding-top: 0%;
  left: -100%;
  transition: 850ms;
  z-index: 2;
  margin-left: -1%;
  margin-top:  calc(-32%);
}

.nav-text_plataforma {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  height: 4vh;
  padding-left:0.5%;
  padding-top:22%;
}
.nav-text_plataforma a {
  text-decoration: none;
  color: white  !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 2vh;
  font-weight: bold;
  position: relative; /* Add this to establish a positioning context */
}
.nav-text_plataforma a::before {
  content: ''; /* Empty content for the pseudo-element */
  width: 35%; /* Adjust the width of the line as needed */
  height: .5vh; /* Adjust the height of the line as needed */
  margin-left: 7%;
  margin-top:15.5%;
  background-color: white  !important; /* Change the color of the line as needed */
  position: absolute; /* Position the line absolutely */
  transform: scaleX(0); /* Initially, set the line width to 0 */
  transform-origin: left; /* Adjust the transform origin to right */
  transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1); /* Transition for hover effect */
}
.nav-text_plataforma a:hover::before {
  transform: scaleX(1); 
  transform-origin: left;
}
.nav-menu-items_plataforma {
  width: 100%;
}
span {
  margin-left: 2vh;
}

.protected-item_plataforma {
  text-decoration: none;
  color: white  !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 2vh;
  margin-left:-1%;
  font-weight: bold;
  position: relative; /* Add this to establish a positioning context */
  cursor: not-allowed; /* Change cursor to indicate non-interactivity */
  /* You can add other styles like opacity, different background, etc. */
}

}

@media screen and (orientation: portrait) and (max-width: 1023px) and (min-height:1800px) {
  /* Style for Navbar */
  .navbar_plataforma {
    position: absolute;
    top: 40%;
    left: 1.5%;
    width: var(--adjusted-width, 10%);
    height: var(--adjusted-height, 10%);
    z-index: 15;
  }
  .navbar_plataforma.active {
      transform: translateX(2px); /* Slide to the right */
      transition: transform 1s ease, left 1s ease; /* Add transition for left */
      left: 31%;
  }
  /* Navbar.css */
  .nav-link_plataforma {
    margin-right: 20px; /* Adjust the spacing as needed */
    text-decoration: none;
    color: black;
    font-size: 3vh;
  }
  /* Add other styles for links as needed */
  .nav-menu_plataforma.active {
    transform: translateX(0px); /* Slide in from the left */
    transition: transform 0.3s ease;
    left:0;
  }
  .menu-bars_plataforma {
    margin-left:0.75vh;
    font-size: 2.6vh;
    background: none;
    color: white;
  }
  .nav-menu_plataforma {
    background-color: transparent;
    width: calc(50%);
    display: flex;
    justify-content: center;
   
  position: absolute;
    padding-top: 0%;
    left: -100%;
    transition: 850ms;
    z-index: 2;
    margin-left: -1%;
    margin-top:  calc(-30%);
  }
  
  .nav-text_plataforma {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    height: 4vh;
    padding-left:0.5%;
    padding-top:22%;
  }
  .nav-text_plataforma a {
    text-decoration: none;
    color: white  !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 4px;
    font-size: 2vh;
    font-weight: bold;
    position: relative; /* Add this to establish a positioning context */
  }
  .nav-text_plataforma a::before {
    content: ''; /* Empty content for the pseudo-element */
    width: 35%; /* Adjust the width of the line as needed */
    height: .5vh; /* Adjust the height of the line as needed */
    margin-left: 7%;
    margin-top:15.5%;
    background-color: white  !important; /* Change the color of the line as needed */
    position: absolute; /* Position the line absolutely */
    transform: scaleX(0); /* Initially, set the line width to 0 */
    transform-origin: left; /* Adjust the transform origin to right */
    transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1); /* Transition for hover effect */
  }
  .nav-text_plataforma a:hover::before {
    transform: scaleX(1); 
    transform-origin: left;
  }
  .nav-menu-items_plataforma {
    width: 100%;
  }
  span {
    margin-left: 2vh;
  }

.protected-item_plataforma {
  text-decoration: none;
  color: white  !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 2vh;
  margin-left:-1%;
  font-weight: bold;
  position: relative; /* Add this to establish a positioning context */
  cursor: not-allowed; /* Change cursor to indicate non-interactivity */
  /* You can add other styles like opacity, different background, etc. */
}

  }
  
@media screen and (orientation: landscape) and (max-width: 1023px)  and (max-height:400px){
  /* Style for Navbar */
  .navbar_plataforma {
    position: absolute;
    top: 35%;
    left: 1.5%;
    width: var(--adjusted-width, 10%);
    height: var(--adjusted-height, 10%);
    z-index: 15;
  }
  .navbar_plataforma.active {
      transform: translateX(2px); /* Slide to the right */
      transition: transform 1s ease, left 1s ease; /* Add transition for left */
      left: 15%;
  }
  /* Navbar.css */
  .nav-link_plataforma {
    margin-right: 20px; /* Adjust the spacing as needed */
    text-decoration: none;
    color: black;
    font-size: 3vh;
  }
  /* Add other styles for links as needed */
  .nav-menu_plataforma.active {
    transform: translateX(0px); /* Slide in from the left */
    transition: transform 0.3s ease;
    left:0;
  }
  .menu-bars_plataforma {
    margin-left:0.75vh;
    font-size: 4vh;
    background: none;
    color: white;
  }
  .nav-menu_plataforma {
    background-color: transparent;
    width: calc(16%);
    display: flex;
    justify-content: center;
    
  position: absolute;
    padding-top: 0%;
    left: -100%;
    transition: 850ms;
    z-index: 2;
    margin-left: -1%;
    margin-top:  calc(-6%);
  }
  
  .nav-text_plataforma {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    height: 4vh;
    padding-left:0.5%;
    padding-top:22%;
  }
  .nav-text_plataforma a {
    text-decoration: none;
    color: white  !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 4px;
    font-size: 3vh;
    font-weight: bold;
    position: relative; /* Add this to establish a positioning context */
  }
  .nav-text_plataforma a::before {
    content: ''; /* Empty content for the pseudo-element */
    width: 35%; /* Adjust the width of the line as needed */
    height: .5vh; /* Adjust the height of the line as needed */
    margin-left: 7%;
    margin-top:15.5%;
    background-color: white  !important; /* Change the color of the line as needed */
    position: absolute; /* Position the line absolutely */
    transform: scaleX(0); /* Initially, set the line width to 0 */
    transform-origin: left; /* Adjust the transform origin to right */
    transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1); /* Transition for hover effect */
  }
  .nav-text_plataforma a:hover::before {
    transform: scaleX(1); 
    transform-origin: left;
  }
  .nav-menu-items_plataforma {
    width: 100%;
  }
  span {
    margin-left: 2vh;
  }

.protected-item_plataforma {
  text-decoration: none;
  color: white  !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 3vh;
  margin-left:-1%;
  font-weight: bold;
  position: relative; /* Add this to establish a positioning context */
  cursor: not-allowed; /* Change cursor to indicate non-interactivity */
  /* You can add other styles like opacity, different background, etc. */
}

  }
  

  @media screen and (orientation: landscape) and (max-width: 1023px)  and (min-height:400px){
    /* Style for Navbar */
    .navbar_plataforma {
      position: absolute;
      top: 35%;
      left: 1.5%;
      width: var(--adjusted-width, 10%);
      height: var(--adjusted-height, 10%);
      z-index: 15;
    }
    .navbar_plataforma.active {
        transform: translateX(2px); /* Slide to the right */
        transition: transform 1s ease, left 1s ease; /* Add transition for left */
        left: 15%;
    }
    /* Navbar.css */
    .nav-link_plataforma {
      margin-right: 20px; /* Adjust the spacing as needed */
      text-decoration: none;
      color: black;
      font-size: 3vh;
    }
    /* Add other styles for links as needed */
    .nav-menu_plataforma.active {
      transform: translateX(0px); /* Slide in from the left */
      transition: transform 0.3s ease;
      left:0;
    }
    .menu-bars_plataforma {
      margin-left:0.75vh;
      font-size: 4vh;
      background: none;
      color: white;
    }
    .nav-menu_plataforma {
      background-color: transparent;
      width: calc(20%);
      display: flex;
      justify-content: center;
      
  position: absolute;
      padding-top: 0%;
      left: -100%;
      transition: 850ms;
      z-index: 2;
      margin-left: -1%;
      margin-top:  calc(-10%);
    }
    
    .nav-text_plataforma {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      list-style: none;
      height: 4vh;
      padding-left:0.5%;
      padding-top:22%;
    }
    .nav-text_plataforma a {
      text-decoration: none;
      color: white  !important;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 10px;
      border-radius: 4px;
      font-size: 3vh;
      font-weight: bold;
      position: relative; /* Add this to establish a positioning context */
    }
    .nav-text_plataforma a::before {
      content: ''; /* Empty content for the pseudo-element */
      width: 35%; /* Adjust the width of the line as needed */
      height: .5vh; /* Adjust the height of the line as needed */
      margin-left: 7%;
      margin-top:15.5%;
      background-color: white  !important; /* Change the color of the line as needed */
      position: absolute; /* Position the line absolutely */
      transform: scaleX(0); /* Initially, set the line width to 0 */
      transform-origin: left; /* Adjust the transform origin to right */
      transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1); /* Transition for hover effect */
    }
    .nav-text_plataforma a:hover::before {
      transform: scaleX(1); 
      transform-origin: left;
    }
    .nav-menu-items_plataforma {
      width: 100%;
    }
    span {
      margin-left: 2vh;
    }
  
  .protected-item_plataforma {
    text-decoration: none;
    color: white  !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 4px;
    font-size: 3vh;
    margin-left:-1%;
    font-weight: bold;
    position: relative; /* Add this to establish a positioning context */
    cursor: not-allowed; /* Change cursor to indicate non-interactivity */
    /* You can add other styles like opacity, different background, etc. */
  }
  
    }
    