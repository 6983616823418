.carousel img {
    object-fit: cover;
}

/* PublicityDisplay.css */
.publicity-display-container {
border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.2);

  /*width: 60%;
  height: auto;
  margin-top: 2%;
  margin-left: 26%;*/
}

/* Adjust the dimensions and other styles as needed */

/* Create a CSS class for image styles */
.image-style_pub{
  width: 130%;
  height:auto;
  object-fit: cover;
}