
.container_about{
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}
.container_about_proj_spe{
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.contactos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-left:15%;

}

.contactos_text {
  margin-top: 4%;
}

.contactos_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  height: auto;
  margin-left:10%;

}
.contactos_text h2{
  margin-left:15%;
  margin-top: 16px;
  font-size: 32px;
  color: #333;
}
.contactos_text h2::after {
  content: '';
  width: calc(60% );
  height: 4px;
  background-color: transparent;
  position: absolute;
  bottom: 10px;
  left: 34%;
  top:12%;
}
.contact_container_pub {
  margin-left: 1%;
  margin-top: 26%;
  padding-top:1%;
  width:12%;
  height:auto;
}
.contact_right-container {
background-color: #E1E8E5;
width: 70%;
height: 75vh;
margin-left: 21.5%;
padding-right: 2%;
padding-bottom: 0%;
overflow-y: auto;
overflow-x: hidden;
justify-content: space-between;
display: flex;
justify-content: space-between; /* To create space between columns */
margin-top : -25%;
}

.cleft-column-a {
  width: 50%;
  border-radius: 10px;
  height: 60vh;
  background-color: #E1E8E5;
  margin-top:4%;
  margin-left:8%;
}

/* Styles for the right column */
.cright-column-a {
  width: 50%;
  height: 60vh;
  margin-top:4%;
  margin-left:3%;
  background-color:#E1E8E5;
  border-radius: 10px;
}/* Styles for the right column */


.copyright_c {
  text-align: center;
  height: 5%;
  color:black;
  flex-grow: 1; /* To center the text */
  margin-left: 20px; /* Adjust spacing between icons and copyright text */
  font-size: 1.5vh; /* Adjust the font size as needed */
   margin-top: 1.25%;
  font-weight: bold;
}


@media screen and (orientation: portrait) and (max-width: 1023px) and (max-height:1800px) {
.container_aboutr{
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}
.container_about{
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}
.container_about_proj_spe{
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.contactos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-left:15%;

}

.contactos_text {
  margin-top: 2%;
}
.contactos_text h2{
  margin-left:22%;
  margin-top: 9%;
  font-size: 34px;
  color: #333;
}
.contactos_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  height: auto;
  margin-left:10%;

}
.contactos_text h2::after {
  content: '';
  width: calc(45% );
  height: 4px;
  background-color: transparent;
  position: absolute;
  bottom: 10px;
  left: 48%;
  top:6.5%;
}
.contact_container_pub {
  margin-left: 1%;
  margin-top: 26%;
  padding-top:1%;
  width:12%;
  height:auto;
}
.contact_right-container {
background-color: #E1E8E5;
width: 60%;
height: 80vh;
margin-left: 33.5%;
padding-right: 2%;
padding-bottom: 0%;
overflow-y: auto;
overflow-x: hidden;
justify-content: space-between;
display: grid;
justify-content: space-between; /* To create space between columns */
margin-top : -25%;
}

.cleft-column-a {
  width: 50%;
  border-radius: 10px;
  height: 60vh;
  background-color: #E1E8E5;
  margin-top:4%;
  margin-left:8%;
}

/* Styles for the right column */
.cright-column-a {
  width: 50%;
  height: 60vh;
  margin-top:4%;
  padding-left:30%;
  background-color:#E1E8E5;
  border-radius: 10px;
}/* Styles for the right column */


.copyright_c {
  text-align: center;
  height: 5%;
  color:black;
  flex-grow: 1; /* To center the text */
  margin-left: 20px; /* Adjust spacing between icons and copyright text */
  font-size: 1.25vh; /* Adjust the font size as needed */
   margin-top: 1.25%;
  font-weight: bold;
}


}


@media screen and (orientation: portrait) and (max-width: 1023px) and (min-height:1800px) {

.container_about{
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}
.container_about_proj_spe{
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.contactos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-left:15%;

}

.contactos_text {
  margin-top: 2%;
}
.contactos_text h2{
  margin-left:22%;
  margin-top: 9%;
  font-size: 34px;
  color: #333;
}
.contactos_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  height: auto;
  margin-left:10%;

}
.contactos_text h2::after {
  content: '';
  width: calc(45% );
  height: 4px;
  background-color: transparent;
  position: absolute;
  bottom: 10px;
  left: 48%;
  top:5.5%;
}
.contact_container_pub {
  margin-left: 1%;
  margin-top: 26%;
  padding-top:1%;
  width:12%;
  height:auto;
}
.contact_right-container {
background-color: #E1E8E5;
width: 60%;
height: 75vh;
margin-left: 33.5%;
padding-right: 2%;
padding-bottom: 0%;
overflow-y: auto;
overflow-x: hidden;
justify-content: space-between;
display: grid;
justify-content: space-between; /* To create space between columns */
margin-top : -18%;
}

.cleft-column-a {
  width: 50%;
  border-radius: 10px;
  height: 60vh;
  background-color: #E1E8E5;
  margin-top:4%;
  margin-left:8%;
}

/* Styles for the right column */
.cright-column-a {
  width: 50%;
  height: 60vh;
  margin-top:4%;
  padding-left:30%;
  background-color:#E1E8E5;
  border-radius: 10px;
}/* Styles for the right column */


.copyright_c {
  text-align: center;
  height: 5%;
  color:black;
  flex-grow: 1; /* To center the text */
  margin-left: 13%; /* Adjust spacing between icons and copyright text */
  font-size: 1.25vh; /* Adjust the font size as needed */
   margin-top: 8%;
  font-weight: bold;
}


}


@media screen and (orientation: landscape) and (max-width: 1023px)  and (max-height:400px){

  .container_about{
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  }
  .container_about_proj_spe{
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  }
  .contactos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin-left:15%;
  
  }
  
  .contactos_text {
    margin-top: 2%;
  }
  .contactos_text h2{
    margin-left:15%;
    margin-top: 16px;
    font-size: 26px;
    color: #333;
  }
  .contactos_text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: auto;
    margin-left:10%;
  
  }
  .contactos_text h2::after {
    content: '';
    width: calc(50% );
    height: 4px;
    background-color: transparent;
    position: absolute;
    bottom: 10px;
    left: 44%;
    top:13%;
  }
  .contact_container_pub {
    margin-left: 1%;
    margin-top: 26%;
    padding-top:1%;
    width:12%;
    height:auto;
  }
  .contact_right-container {
  background-color: #E1E8E5;
  width: 60%;
  height: 275vh;
  margin-left: 28.5%;
  padding-right: 2%;
  padding-bottom: 0%;
  overflow-y: hidden;
  overflow-x: hidden;
  justify-content: space-between;
  display: grid;
  justify-content: space-between; /* To create space between columns */
  margin-top : -25%;
  }
  
  .cleft-column-a {
    width: 50%;
    border-radius: 10px;
    height: 60vh;
    background-color: #E1E8E5;
    margin-top:4%;
    margin-left:8%;
  }
  
  /* Styles for the right column */
  .cright-column-a {
    width: 50%;
    height: 60vh;
    margin-top:4%;
    padding-left:30%;
    background-color:#E1E8E5;
    border-radius: 10px;
  }/* Styles for the right column */
  
  
  .copyright_c {
    text-align: center;
    height: 5%;
    color:black;
    flex-grow: 1; /* To center the text */
    margin-left: 13%; /* Adjust spacing between icons and copyright text */
    font-size: 1.25vh; /* Adjust the font size as needed */
     margin-top: 2%;
    font-weight: bold;
  }
  
}


@media screen and (orientation: landscape) and (max-width: 1023px)  and (min-height:400px){

  .container_about{
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  }
  .container_about_proj_spe{
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  }
  .contactos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin-left:15%;
  
  }
  
  .contactos_text {
    margin-top: 2%;
  }
  .contactos_text h2{
    margin-left:15%;
    margin-top: 16px;
    font-size: 26px;
    color: #333;
  }
  .contactos_text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: auto;
    margin-left:10%;
  
  }
  .contactos_text h2::after {
    content: '';
    width: calc(53% );
    height: 4px;
    background-color: transparent;
    position: absolute;
    bottom: 10px;
    left: 40%;
    top:10%;
  }
  .contact_container_pub {
    margin-left: 1%;
    margin-top: 26%;
    padding-top:1%;
    width:12%;
    height:auto;
  }
  .contact_right-container {
  background-color: #E1E8E5;
  width: 60%;
  height: 275vh;
  margin-left: 28.5%;
  padding-right: 2%;
  padding-bottom: 0%;
  overflow-y: hidden;
  overflow-x: hidden;
  justify-content: space-between;
  display: grid;
  justify-content: space-between; /* To create space between columns */
  margin-top : -25%;
  }
  
  .cleft-column-a {
    width: 50%;
    border-radius: 10px;
    height: 60vh;
    background-color: #E1E8E5;
    margin-top:4%;
    margin-left:8%;
  }
  
  /* Styles for the right column */
  .cright-column-a {
    width: 50%;
    height: 60vh;
    margin-top:4%;
    padding-left:30%;
    background-color:#E1E8E5;
    border-radius: 10px;
  }/* Styles for the right column */
  
  
  .copyright_c {
    text-align: center;
    height: 5%;
    color:black;
    flex-grow: 1; /* To center the text */
    margin-left: 13%; /* Adjust spacing between icons and copyright text */
    font-size: 1.25vh; /* Adjust the font size as needed */
     margin-top: 2%;
    font-weight: bold;
  }
}