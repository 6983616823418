.about_container {
  position: relative;
}
.container_aboutt{
  height: 100vh; 
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}

.about_content {
  position: relative;
  width: 100%;
  height: 100vh; 
  background-image: url('../projectos/PLATAFORMA/Plataforma_2.JPG');
  background-size: cover;
  background-position: center;
}

.about_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-text {
  position: absolute;
  top: 75%;
  left: 30.5%;
  transform: translate(-50%, -50%);
  color: #fff; 
  width: 15%;
  text-align: justify; 
}

.centered-text h1 {
  font-size: 40px; 
  margin-bottom: 10px;

  color:gainsboro;
  font-weight: bold;
}

.centered-text h2 {
  font-size: 30px; 
  margin-bottom: 30px;
  font-weight: bold;
  color:gainsboro;
}
.centered-text p {
  font-size: 16px; 
  margin-bottom: 0;
  color:gainsboro;
}
.oferecemos {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  width: 70%;
  margin-left:15%;

}
  
.oferecemos img.uniform-image {  
  width: 94% !important; /* or any specific size you prefer */
  height: auto !important; /* maintains aspect ratio */
  
  border-radius: 8px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0;
  }
.oferecemos img {
  width: 32%; 
  height: auto; 
  border-radius: 8px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0; 
}
.oferecemos_text {
  margin-top: 6%;
}
.oferecemos_text h2{
  margin-left:15%;
  margin-top: 20px;
  font-size: 28px;
  color: #333;
}

.oferecemos_text {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  width: 70%;
  height: auto;
  margin-left:4.5%;

}


.oferecemos_text h2::after {
  content: ''; 
  width: calc(57% );
  height: 4px; 
  background-color: transparent; 
  position: absolute; 
  bottom: 10px; 
  left: 28%;
  top:115.5%;
}
.oferecemos_text_2 {
  margin-left: 6%;
}
.oferecemos_text_2 p{
  font-size: 28px; 
  margin-left: 18%;
  margin-top: 4%;
  margin-right: 18%;
  color:black;
  text-align: center; /* Align the text */
  font-style: italic; /* Make the text italic */
}
.oferecemos_text_2 p1{
  font-size: 28px; 
  margin-left: 19%;
  margin-top: 4%;
  margin-right: 18%;
  color:black;
}
.oferecemos_text_2 p2{
  font-size: 28px; 
  margin-left: 22%;
  margin-top: 4%;
  margin-right: 18%;
  color:black;
}

.nos {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  width: 72%;
  height: auto;
  margin-left:15%;

}

.nos img {
  max-width: 100%; /* Ensures image does not overflow its container */
  height: auto; /* Maintains aspect ratio */
}

.first-row img {
  width: calc(100% ); /* 6 images in the first row */
}
.second-row {
  padding-left:3%;
}
.second-row img {
  width: calc(100% - 100% /5); /* 5 images in the second row */
  
}
.second-row {
  padding-left: 3%;
}

.second-row img {
  width: calc(100% - 100% /5); /* Adjust width and account for space-between */
}



.nos_text {
  margin-top: 4%;
}

.nos_text h2{
  margin-left:15%;
  margin-top: 20px;
  font-size: 28px;
  color: #333; 
}

.nos_text h2::after {
  content: ''; 
  width: calc(65% ); 
  height: 4px; 
  background-color: transparent; 
  position: absolute; 
  bottom: 10px; 
  left: 20%;
  top:183.5%;
}
.team-member {
  padding-top:2%;

}
.team-member p {
  font-size: 1vw; /* Adjust the font size as needed */
  color: gray;
  margin-left:0%;
}

.team-member p1 {
  font-size: .7vw; /* Adjust the font size as needed */
  color: gray;
  margin-left:0%;
  margin-top:-8%;
}

.image-grid {
  grid-template-columns: repeat(6, 1fr); 
  grid-gap: 10px;
}

.image-grid img {
  max-width: 97%;
  height: auto; 
}

.iniciativa_text {
  margin-top: 0%;
}

.iniciativa_text h2{
  margin-left:2%;
  margin-top: 5%;
  font-size: 28px;
  color: #333; 
}

.iniciativa_text h2::after {
  content: ''; 
  width: calc(85% ); 
  height: 4px; 
  background-color: transparent; 
  position: absolute; 
  bottom: 10px; 
  left: 12%;
  top:263%;
}
.iniciativa_text img {
  width: 12%;
  height: auto;
  z-index: 0;
  padding-left:2%;
}
.agradecimento {

  position: relative;
  width: 100%;
  height: 22%; 
  background-color: rgba(255, 221, 153, 0.9);
  background-size: cover;
  background-position: center;
}

.agradecimento h2{
  padding-top:2%;
  margin-left:15%;
  margin-top: 20px;
  font-size: 1vw;
  margin-right:15%;
  margin-bottom:1.5%;
  text-align: center; 
  color: gray; 
  
}

.agradecimento h3{
  padding-top:.1%;
  margin-left:15%;
  margin-right:12%;
  font-size: .8vw;
  color: gray; 
  text-align: center; 
}

.agradecimento h3::after {
  content: ''; 
  width: calc(53.5% ); 
  height: 4px; 
  background-color: transparent; 
  position: absolute;
  top:22.5%;
  left:34%
}
.copyright_a {
  text-align: center;
  color:black;
  flex-grow: 1; /* To center the text */
  margin-left: 20px; /* Adjust spacing between icons and copyright text */
  font-size: 14px; /* Adjust the font size as needed */
   margin-top: 2%;
  font-weight: bold;
}

@media screen and  (min-width: 1024px) and  (max-width: 1600px){
  
  .centered-text {
    position: absolute;
    top: 72%;
    left: 30.5%;
    transform: translate(-50%, -50%);
    color: #fff; 
    width: 18%;
    text-align: justify; 
  }
  
  .centered-text h1 {
    font-size: 40px; 
    margin-bottom: 10px;
  
    color:gainsboro;
    font-weight: bold;
  }
  
  .centered-text h2 {
    font-size: 30px; 
    margin-bottom: 30px;
    font-weight: bold;
    color:gainsboro;
  }
  .centered-text p {
    font-size: 16px; 
    margin-bottom: 0;
    color:gainsboro;
  }
  .oferecemos {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    width: 70%;
    margin-left:15%;
  
  }
  
  .oferecemos img.uniform-image {  
    width: 94% !important; /* or any specific size you prefer */
    height: auto !important; /* maintains aspect ratio */
    
    border-radius: 8px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 0;
    }
  .oferecemos img {
    width: 35%; 
    height: auto; 
    border-radius: 8px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 0; 
  }


  .oferecemos_text {
    margin-top: 5.2%;
  }
  .oferecemos_text h2{
    margin-left:15%;
    margin-top: 20px;
    font-size: 28px;
    color: #333;
  }
  
  .oferecemos_text {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    width: 70%;
    height: auto;
    margin-left:4.5%;
  
  }
  
  
  .oferecemos_text h2::after {
    content: ''; 
    width: calc(57% );
    height: 4px; 
    background-color: transparent; 
    position: absolute; 
    bottom: 10px; 
    left: 28%;
    top:115.5%;
  }
  .oferecemos_text_2 {
    margin-left: 6%;
  }
  .oferecemos_text_2 p{
    font-size: 28px; 
    margin-left: 18%;
    margin-top: 4%;
    margin-right: 18%;
    color:black;
    text-align: center; /* Align the text */
    font-style: italic; /* Make the text italic */
  }
  .oferecemos_text_2 p1{
    font-size: 28px; 
    margin-left: 19%;
    margin-top: 4%;
    margin-right: 18%;
    color:black;
  }
  .oferecemos_text_2 p2{
    font-size: 28px; 
    margin-left: 22%;
    margin-top: 4%;
    margin-right: 18%;
    color:black;
  }
  
  .nos {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    width: 72%;
    height: auto;
    margin-left:15%;
  
  }
  
  .nos_text {
    margin-top: 3%;
  }
  
  .nos_text h2{
    margin-left:15%;
    margin-top: 20px;
    font-size: 28px;
    color: #333; 
  }
  
  .nos_text h2::after {
    content: ''; 
    width: calc(65% ); 
    height: 4px; 
    background-color: transparent; 
    position: absolute; 
    bottom: 10px; 
    left: 20%;
    top:192.2%;
  }
  
  .team-member p {
    font-size: 1vw; /* Adjust the font size as needed */
    color: gray;
  }
  .team-member p {
    font-size: 1vw; /* Adjust the font size as needed */
    color: gray;
    margin-left:0%;
  }
  
  .team-member p1 {
    font-size: .65vw; /* Adjust the font size as needed */
    color: gray;
    margin-left:0%;
    margin-top:-8%;
  }
  .image-grid {
    grid-template-columns: repeat(6, 1fr); 
    grid-gap: 10px;
  }
  
  .image-grid img {
    max-width: 97%;
    height: auto; 
  }
  .iniciativa_text {
    margin-top: 0%;
  }
  
  .iniciativa_text h2{
    margin-left:2%;
    margin-top: 4%;
    font-size: 28px;
    color: #333; 
  }
  
  .iniciativa_text h2::after {
    content: ''; 
    width: calc(85% ); 
    height: 4px; 
    background-color: transparent; 
    position: absolute; 
    bottom: 10px; 
    left: 12%;
    top:272.6%;
  }
  .iniciativa_text img {
    width: 12%;
    height: auto;
    z-index: 0;
    padding-left:2%;
  }
  .agradecimento {
  
    position: relative;
    width: 100%;
    height: 22%; 
    background-color: rgba(255, 221, 153, 0.9);
    background-size: cover;
    background-position: center;
  }
  
  .agradecimento h2{
    padding-top:2%;
    margin-left:15%;
    margin-top: 20px;
    font-size: 1vw;
    margin-right:15%;
    margin-bottom:1.5%;
    color: gray; 
    
  }
  
  .agradecimento h3{
    padding-top:.1%;
    margin-left:15%;
    margin-right:12%;
    font-size: .8vw;
    color: gray; 
    text-align: center; 
  }
  
  .agradecimento h3::after {
    content: ''; 
    width: calc(53.5% ); 
    height: 4px; 
    background-color: transparent; 
    position: absolute;
    top:22.5%;
    left:34%
  }
  .copyright_a {
    text-align: center;
    color:black;
    flex-grow: 1; /* To center the text */
    margin-left: 20px; /* Adjust spacing between icons and copyright text */
    font-size: 14px; /* Adjust the font size as needed */
     margin-top: 2%;
    font-weight: bold;
  }

  
  }
  
  
  @media screen and (orientation: portrait) and (max-width: 1023px) and (min-height:1800px) {

    .container_aboutt{
      height: 90vh; 
      overflow-x: hidden;
      overflow-y: scroll;
      position: relative;
    }
    
    .centered-text {
      position: absolute;
      top: 72%;
      left: 70%;
      transform: translate(-50%, -50%);
      color: #fff; 
      width: 30%;
      text-align: justify; 
    }
    
    .centered-text h1 {
      font-size: 40px; 
      margin-bottom: 10px;
    
      color:gainsboro;
      font-weight: bold;
    }
    
    .centered-text h2 {
      font-size: 30px; 
      margin-bottom: 30px;
      font-weight: bold;
      color:gainsboro;
    }
    .centered-text p {
      font-size: 20px; 
      margin-bottom: 0;
      color:gainsboro;
    }
    .oferecemos {
      display: flex;
      justify-content: space-between; 
      align-items: center; 
      width: 70%;
      margin-left:15%;
    
    }
    
     
  .oferecemos img.uniform-image {  
    width: 94% !important; /* or any specific size you prefer */
    height: auto !important; /* maintains aspect ratio */
    
    border-radius: 8px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 0;
    }
    .oferecemos img {
      width: 32%; 
      height: auto; 
      border-radius: 8px; 
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      margin: 0; 
    }
    .oferecemos_text {
      margin-top: 5.2%;
    }
    .oferecemos_text h2{
      margin-left:15%;
      margin-top: 20px;
      font-size: 28px;
      color: #333;
    }
    
    .oferecemos_text {
      display: flex;
      justify-content: space-between;
      align-items: center; 
      width: 70%;
      height: auto;
      margin-left:4.5%;
    
    }
    
    
    .oferecemos_text h2::after {
      content: ''; 
      width: calc(57% );
      height: 4px; 
      background-color: transparent; 
      position: absolute; 
      bottom: 10px; 
      left: 28%;
      top:0%;
    }
    .oferecemos_text_2 {
      margin-left: 6%;
    }
    .oferecemos_text_2 p{
      font-size: 28px; 
      margin-left: 18%;
      margin-top: 4%;
      margin-right: 18%;
      color:black;
      text-align: center; /* Align the text */
      font-style: italic; /* Make the text italic */
    }
    .oferecemos_text_2 p1{
      font-size: 28px; 
      margin-left: 19%;
      margin-top: 4%;
      margin-right: 18%;
      color:black;
    }
    .oferecemos_text_2 p2{
      font-size: 28px; 
      margin-left: 22%;
      margin-top: 4%;
      margin-right: 18%;
      color:black;
    }
    
    .nos {
      display: flex;
      justify-content: space-between;
      align-items: center; 
      width: 72%;
      height: auto;
      margin-left:15%;
    
    }
    
    .nos_text {
      margin-top: 3%;
    }
    
    .nos_text h2{
      margin-left:15%;
      margin-top: 20px;
      font-size: 28px;
      color: #333; 
    }
    
    .nos_text h2::after {
      content: ''; 
      width: calc(65% ); 
      height: 4px; 
      background-color: transparent; 
      position: absolute; 
      bottom: 10px; 
      left: 20%;
      top:0%;
    }
    
    .team-member p {
      font-size: 1vw; /* Adjust the font size as needed */
      color: gray;
    }
    
  .team-member p {
    font-size: 1vw; /* Adjust the font size as needed */
    color: gray;
    margin-left:0%;
  }
  
  .team-member p1 {
    font-size: .65vw; /* Adjust the font size as needed */
    color: gray;
    margin-left:0%;
    margin-top:-8%;
  }
    .image-grid {
      grid-template-columns: repeat(6, 1fr); 
      grid-gap: 10px;
    }
    
    .image-grid img {
      max-width: 97%;
      height: auto; 
    }
    .iniciativa_text {
      margin-top: 0%;
    }
    
    .iniciativa_text h2{
      margin-left:2%;
      margin-top: 4%;
      font-size: 28px;
      color: #333; 
    }
    
    .iniciativa_text h2::after {
      content: ''; 
      width: calc(85% ); 
      height: 4px; 
      background-color: transparent; 
      position: absolute; 
      bottom: 10px; 
      left: 12%;
      top:100%;
    }
    .iniciativa_text img {
      width: 12%;
      height: auto;
      z-index: 0;
      padding-left:2%;
    }
    .agradecimento {
    
      position: relative;
      width: 100%;
      height: 6%; 
      background-color: rgba(255, 221, 153, 0.9);
      background-size: cover;
      background-position: center;
    }
    
    .agradecimento h2{
      padding-top:2%;
      margin-left:15%;
      margin-top: 20px;
      font-size: 1vw;
      margin-right:15%;
      margin-bottom:1.5%;
      color: gray; 
      
    }
    
    .agradecimento h3{
      padding-top:.1%;
      margin-left:15%;
      margin-right:12%;
      font-size: .8vw;
      color: gray; 
      text-align: center; 
    }
    
    .agradecimento h3::after {
      content: ''; 
      width: calc(53.5% ); 
      height: 4px; 
      background-color: transparent; 
      position: absolute;
      top:0%;
      left:34%
    }
    .copyright_a {
      text-align: center;
      color:black;
      flex-grow: 1; /* To center the text */
      margin-left: 20px; /* Adjust spacing between icons and copyright text */
      font-size: 14px; /* Adjust the font size as needed */
       margin-top: 2%;
      font-weight: bold;
    }
  
    

  }
  @media screen and (orientation: landscape) and (max-width: 1023px)  and (max-height:400px){

    .container_aboutt{
      height: 78vh; 
      overflow-x: hidden;
      overflow-y: scroll;
      position: relative;
    }
    
    .centered-text {
      position: absolute;
      top: 52%;
      left: 75%;
      transform: translate(-50%, -50%);
      color: #fff; 
      width: 30%;
      text-align: justify; 
    }
    
    .centered-text h1 {
      font-size: 20px; 
      margin-bottom: 10px;
    
      color:gainsboro;
      font-weight: bold;
    }
    
    .centered-text h2 {
      font-size: 20px; 
      margin-bottom: 30px;
      font-weight: bold;
      color:gainsboro;
    }
    .centered-text p {
      font-size: 12px; 
      margin-bottom: 0;
      color:gainsboro;
    }
    .oferecemos {
      display: flex;
      justify-content: space-between; 
      align-items: center; 
      width: 70%;
      margin-left:15%;
    
    }
      
  .oferecemos img.uniform-image {  
    width: 94% !important; /* or any specific size you prefer */
    height: auto !important; /* maintains aspect ratio */
    
    border-radius: 8px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 0;
    }
    .oferecemos img {
      width: 32%; 
      height: auto; 
      border-radius: 8px; 
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      margin: 0; 
    }
    .oferecemos_text {
      margin-top: 5.2%;
    }
    .oferecemos_text h2{
      margin-left:15%;
      margin-top: 20px;
      font-size: 28px;
      color: #333;
    }
    
    .oferecemos_text {
      display: flex;
      justify-content: space-between;
      align-items: center; 
      width: 70%;
      height: auto;
      margin-left:4.5%;
    
    }
    
    
    .oferecemos_text h2::after {
      content: ''; 
      width: calc(57% );
      height: 4px; 
      background-color: transparent; 
      position: absolute; 
      bottom: 10px; 
      left: 28%;
      top:0%;
    }
    .oferecemos_text_2 {
      margin-left: 6%;
    }
    .oferecemos_text_2 p{
      font-size: 28px; 
      margin-left: 18%;
      margin-top: 4%;
      margin-right: 18%;
      color:black;
      text-align: center; /* Align the text */
      font-style: italic; /* Make the text italic */
    }
    .oferecemos_text_2 p1{
      font-size: 28px; 
      margin-left: 19%;
      margin-top: 4%;
      margin-right: 18%;
      color:black;
    }
    .oferecemos_text_2 p2{
      font-size: 28px; 
      margin-left: 22%;
      margin-top: 4%;
      margin-right: 18%;
      color:black;
    }
    
    .nos {
      display: flex;
      justify-content: space-between;
      align-items: center; 
      width: 72%;
      height: auto;
      margin-left:15%;
    
    }
    
    .nos_text {
      margin-top: 3%;
    }
    
    .nos_text h2{
      margin-left:15%;
      margin-top: 20px;
      font-size: 28px;
      color: #333; 
    }
    
    .nos_text h2::after {
      content: ''; 
      width: calc(65% ); 
      height: 4px; 
      background-color: transparent; 
      position: absolute; 
      bottom: 10px; 
      left: 20%;
      top:0%;
    }
    
    .team-member p {
      font-size: 1vw; /* Adjust the font size as needed */
      color: gray;
    }
    
  .team-member p {
    font-size: 1vw; /* Adjust the font size as needed */
    color: gray;
    margin-left:0%;
  }
  
  .team-member p1 {
    font-size: .65vw; /* Adjust the font size as needed */
    color: gray;
    margin-left:0%;
    margin-top:-8%;
  }
    .image-grid {
      grid-template-columns: repeat(6, 1fr); 
      grid-gap: 10px;
    }
    
    .image-grid img {
      max-width: 97%;
      height: auto; 
    }
    .iniciativa_text {
      margin-top: 0%;
    }
    
    .iniciativa_text h2{
      margin-left:2%;
      margin-top: 4%;
      font-size: 28px;
      color: #333; 
    }
    
    .iniciativa_text h2::after {
      content: ''; 
      width: calc(85% ); 
      height: 4px; 
      background-color: transparent; 
      position: absolute; 
      bottom: 10px; 
      left: 12%;
      top:100%;
    }
    .iniciativa_text img {
      width: 12%;
      height: auto;
      z-index: 0;
      padding-left:2%;
    }
    .agradecimento {
    
      position: relative;
      width: 100%;
      height: 30%; 
      background-color: rgba(255, 221, 153, 0.9);
      background-size: cover;
      background-position: center;
    }
    
    .agradecimento h2{
      padding-top:2%;
      margin-left:15%;
      margin-top: 20px;
      font-size: 1vw;
      margin-right:15%;
      margin-bottom:1.5%;
      color: gray; 
      
    }
    
    .agradecimento h3{
      padding-top:.1%;
      margin-left:15%;
      margin-right:12%;
      font-size: .8vw;
      color: gray; 
      text-align: center; 
    }
    
    .agradecimento h3::after {
      content: ''; 
      width: calc(53.5% ); 
      height: 4px; 
      background-color: transparent; 
      position: absolute;
      top:0%;
      left:34%
    }
    .copyright_a {
      text-align: center;
      color:black;
      flex-grow: 1; /* To center the text */
      margin-left: 20px; /* Adjust spacing between icons and copyright text */
      font-size: 12px; /* Adjust the font size as needed */
       margin-top: 2%;
      font-weight: bold;
    }
  
  }
  
  
  
  @media screen and (orientation: portrait) and (max-width: 1023px) and (max-height:1800px) {

    .container_aboutt{
      height: 90vh; 
      overflow-x: hidden;
      overflow-y: scroll;
      position: relative;
    }
    
    .centered-text {
      position: absolute;
      top: 72%;
      left: 70%;
      transform: translate(-50%, -50%);
      color: #fff; 
      width: 30%;
      text-align: justify; 
    }
    
    .centered-text h1 {
      font-size: 40px; 
      margin-bottom: 10px;
    
      color:gainsboro;
      font-weight: bold;
    }
    
    .centered-text h2 {
      font-size: 30px; 
      margin-bottom: 30px;
      font-weight: bold;
      color:gainsboro;
    }
    .centered-text p {
      font-size: 20px; 
      margin-bottom: 0;
      color:gainsboro;
    }
    .oferecemos {
      display: flex;
      justify-content: space-between; 
      align-items: center; 
      width: 70%;
      margin-left:15%;
    
    }
    
    .oferecemos img {
      width: 32%; 
      height: auto; 
      border-radius: 8px; 
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      margin: 0; 
    }
    .oferecemos_text {
      margin-top: 5.2%;
    }
    .oferecemos_text h2{
      margin-left:15%;
      margin-top: 20px;
      font-size: 28px;
      color: #333;
    }
    
    .oferecemos_text {
      display: flex;
      justify-content: space-between;
      align-items: center; 
      width: 70%;
      height: auto;
      margin-left:4.5%;
    
    }
    
    
    .oferecemos_text h2::after {
      content: ''; 
      width: calc(57% );
      height: 4px; 
      background-color: transparent; 
      position: absolute; 
      bottom: 10px; 
      left: 28%;
      top:0%;
    }
    .oferecemos_text_2 {
      margin-left: 6%;
    }
    .oferecemos_text_2 p{
      font-size: 28px; 
      margin-left: 18%;
      margin-top: 4%;
      margin-right: 18%;
      color:black;
      text-align: center; /* Align the text */
      font-style: italic; /* Make the text italic */
    }
    .oferecemos_text_2 p1{
      font-size: 28px; 
      margin-left: 19%;
      margin-top: 4%;
      margin-right: 18%;
      color:black;
    }
    .oferecemos_text_2 p2{
      font-size: 28px; 
      margin-left: 22%;
      margin-top: 4%;
      margin-right: 18%;
      color:black;
    }
    
    .nos {
      display: flex;
      justify-content: space-between;
      align-items: center; 
      width: 72%;
      height: auto;
      margin-left:15%;
    
    }
    
    .nos_text {
      margin-top: 3%;
    }
    
    .nos_text h2{
      margin-left:15%;
      margin-top: 20px;
      font-size: 28px;
      color: #333; 
    }
    
    .nos_text h2::after {
      content: ''; 
      width: calc(65% ); 
      height: 4px; 
      background-color: transparent; 
      position: absolute; 
      bottom: 10px; 
      left: 20%;
      top:0%;
    }
    
    .team-member p {
      font-size: 1vw; /* Adjust the font size as needed */
      color: gray;
    }
    
  .team-member p {
    font-size: 1vw; /* Adjust the font size as needed */
    color: gray;
    margin-left:0%;
  }
  
  .team-member p1 {
    font-size: .65vw; /* Adjust the font size as needed */
    color: gray;
    margin-left:0%;
    margin-top:-8%;
  }
    .image-grid {
      grid-template-columns: repeat(6, 1fr); 
      grid-gap: 10px;
    }
    
    .image-grid img {
      max-width: 97%;
      height: auto; 
    }
    .iniciativa_text {
      margin-top: 0%;
    }
    
    .iniciativa_text h2{
      margin-left:2%;
      margin-top: 4%;
      font-size: 28px;
      color: #333; 
    }
    
    .iniciativa_text h2::after {
      content: ''; 
      width: calc(85% ); 
      height: 4px; 
      background-color: transparent; 
      position: absolute; 
      bottom: 10px; 
      left: 12%;
      top:100%;
    }
    .iniciativa_text img {
      width: 12%;
      height: auto;
      z-index: 0;
      padding-left:2%;
    }
    .agradecimento {
    
      position: relative;
      width: 100%;
      height: 6%; 
      background-color: rgba(255, 221, 153, 0.9);
      background-size: cover;
      background-position: center;
    }
    
    .agradecimento h2{
      padding-top:2%;
      margin-left:15%;
      margin-top: 20px;
      font-size: 1vw;
      margin-right:15%;
      margin-bottom:1.5%;
      color: gray; 
      
    }
    
    .agradecimento h3{
      padding-top:.1%;
      margin-left:15%;
      margin-right:12%;
      font-size: .8vw;
      color: gray; 
      text-align: center; 
    }
    
    .agradecimento h3::after {
      content: ''; 
      width: calc(53.5% ); 
      height: 4px; 
      background-color: transparent; 
      position: absolute;
      top:0%;
      left:34%
    }
    .copyright_a {
      text-align: center;
      color:black;
      flex-grow: 1; /* To center the text */
      margin-left: 20px; /* Adjust spacing between icons and copyright text */
      font-size: 14px; /* Adjust the font size as needed */
       margin-top: 2%;
      font-weight: bold;
    }
  
    

  }
  @media screen and (orientation: landscape) and (max-width: 1023px)  and (min-height:400px){

    .container_aboutt{
      height: 78vh; 
      overflow-x: hidden;
      overflow-y: scroll;
      position: relative;
    }
    
    .centered-text {
      position: absolute;
      top: 52%;
      left: 75%;
      transform: translate(-50%, -50%);
      color: #fff; 
      width: 30%;
      text-align: justify; 
    }
    
    .centered-text h1 {
      font-size: 20px; 
      margin-bottom: 10px;
    
      color:gainsboro;
      font-weight: bold;
    }
    
    .centered-text h2 {
      font-size: 20px; 
      margin-bottom: 30px;
      font-weight: bold;
      color:gainsboro;
    }
    .centered-text p {
      font-size: 12px; 
      margin-bottom: 0;
      color:gainsboro;
    }
    .oferecemos {
      display: flex;
      justify-content: space-between; 
      align-items: center; 
      width: 70%;
      margin-left:15%;
    
    }
    
    .oferecemos img {
      width: 32%; 
      height: auto; 
      border-radius: 8px; 
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      margin: 0; 
    }
    .oferecemos_text {
      margin-top: 5.2%;
    }
    .oferecemos_text h2{
      margin-left:15%;
      margin-top: 20px;
      font-size: 28px;
      color: #333;
    }
    
    .oferecemos_text {
      display: flex;
      justify-content: space-between;
      align-items: center; 
      width: 70%;
      height: auto;
      margin-left:4.5%;
    
    }
    
    
    .oferecemos_text h2::after {
      content: ''; 
      width: calc(57% );
      height: 4px; 
      background-color: transparent; 
      position: absolute; 
      bottom: 10px; 
      left: 28%;
      top:0%;
    }
    .oferecemos_text_2 {
      margin-left: 6%;
    }
    .oferecemos_text_2 p{
      font-size: 28px; 
      margin-left: 18%;
      margin-top: 4%;
      margin-right: 18%;
      color:black;
      text-align: center; /* Align the text */
      font-style: italic; /* Make the text italic */
    }
    .oferecemos_text_2 p1{
      font-size: 28px; 
      margin-left: 19%;
      margin-top: 4%;
      margin-right: 18%;
      color:black;
    }
    .oferecemos_text_2 p2{
      font-size: 28px; 
      margin-left: 22%;
      margin-top: 4%;
      margin-right: 18%;
      color:black;
    }
    
    .nos {
      display: flex;
      justify-content: space-between;
      align-items: center; 
      width: 72%;
      height: auto;
      margin-left:15%;
    
    }
    
    .nos_text {
      margin-top: 3%;
    }
    
    .nos_text h2{
      margin-left:15%;
      margin-top: 20px;
      font-size: 28px;
      color: #333; 
    }
    
    .nos_text h2::after {
      content: ''; 
      width: calc(65% ); 
      height: 4px; 
      background-color: transparent; 
      position: absolute; 
      bottom: 10px; 
      left: 20%;
      top:0%;
    }
    
    .team-member p {
      font-size: 1vw; /* Adjust the font size as needed */
      color: gray;
    }
    
  .team-member p {
    font-size: 1vw; /* Adjust the font size as needed */
    color: gray;
    margin-left:0%;
  }
  
  .team-member p1 {
    font-size: .65vw; /* Adjust the font size as needed */
    color: gray;
    margin-left:0%;
    margin-top:-8%;
  }
    .image-grid {
      grid-template-columns: repeat(6, 1fr); 
      grid-gap: 10px;
    }
    
    .image-grid img {
      max-width: 97%;
      height: auto; 
    }
    .iniciativa_text {
      margin-top: 0%;
    }
    
    .iniciativa_text h2{
      margin-left:2%;
      margin-top: 4%;
      font-size: 28px;
      color: #333; 
    }
    
    .iniciativa_text h2::after {
      content: ''; 
      width: calc(85% ); 
      height: 4px; 
      background-color: transparent; 
      position: absolute; 
      bottom: 10px; 
      left: 12%;
      top:100%;
    }
    .iniciativa_text img {
      width: 12%;
      height: auto;
      z-index: 0;
      padding-left:2%;
    }
    .agradecimento {
    
      position: relative;
      width: 100%;
      height: 30%; 
      background-color: rgba(255, 221, 153, 0.9);
      background-size: cover;
      background-position: center;
    }
    
    .agradecimento h2{
      padding-top:2%;
      margin-left:15%;
      margin-top: 20px;
      font-size: 1vw;
      margin-right:15%;
      margin-bottom:1.5%;
      color: gray; 
      
    }
    
    .agradecimento h3{
      padding-top:.1%;
      margin-left:15%;
      margin-right:12%;
      font-size: .8vw;
      color: gray; 
      text-align: center; 
    }
    
    .agradecimento h3::after {
      content: ''; 
      width: calc(53.5% ); 
      height: 4px; 
      background-color: transparent; 
      position: absolute;
      top:0%;
      left:34%
    }
    .copyright_a {
      text-align: center;
      color:black;
      flex-grow: 1; /* To center the text */
      margin-left: 20px; /* Adjust spacing between icons and copyright text */
      font-size: 12px; /* Adjust the font size as needed */
       margin-top: 2%;
      font-weight: bold;
    }
  
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  