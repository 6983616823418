/* Footer.css */

.footer_plataforma {
  position: absolute;
    
  top: 96%;
  left: -43.2%;
    width: 100%;
    
    z-index: 12000;
      height:3%;
}

.footer_socials_plataforma {
  display: flex;
  justify-content: center;
}

.social-icons0_plataforma {
  display: flex;
  align-items: center;
  gap: 15px; /* Adjust spacing between icons */
}

/* Additional styles for icons if needed */
.social-icons0_plataforma a {
  font-size: 20px;
  color: #FFF; /* Change icon color if needed */
}
.footer_plataforma a:hover {
  text-decoration: underline; /* Add an underline on hover */
}


@media screen and (orientation: portrait) and (max-width: 1023px) and (min-height:1800px) {
/* Footer.css */

.footer_plataforma {
  position: absolute;
    
  top: 87%;
  left: -38.5%;
    width: 100%;
    
    z-index: 12000;
      height:3%;
}

.footer_socials_plataforma {
  display: flex;
  justify-content: center;
}

.social-icons0_plataforma {
  display: flex;
  align-items: center;
  gap: 15px; /* Adjust spacing between icons */
}

/* Additional styles for icons if needed */
.social-icons0_plataforma a {
  font-size: 26px;
  color: #FFF; /* Change icon color if needed */
}
.footer_plataforma a:hover {
  text-decoration: underline; /* Add an underline on hover */
}
}


@media screen and (orientation: portrait) and (max-width: 1023px) and (max-height:1800px) {
  /* Footer.css */

.footer_plataforma {
  position: absolute;
    
  top: 86%;
  left: 1.5%;
    width: 100%;
    
    z-index: 12000;
      height:3%;
}

.footer_socials_plataforma {
  display: flex;
  justify-content: center;
}

.social-icons0_plataforma {
  display: flex;
  align-items: center;
  gap: 15px; /* Adjust spacing between icons */
}

/* Additional styles for icons if needed */
.social-icons0_plataforma a {
  font-size: 26px;
  color: #FFF; /* Change icon color if needed */
}
.footer_plataforma a:hover {
  text-decoration: underline; /* Add an underline on hover */
}
}


@media screen and (orientation: landscape) and (max-width: 1023px)  and (max-height:400px){
  /* Footer.css */

  .footer_plataforma {
    position: absolute;
      
    top: 78%;
    left: 1.5%;
      width: 100%;
     
    z-index: 12000;
        height:3%;
  }
  
  .footer_socials_plataforma {
    display: flex;
    justify-content: center;
  }
  
  .social-icons0_plataforma {
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust spacing between icons */
  }
  
  /* Additional styles for icons if needed */
  .social-icons0_plataforma a {
    font-size: 16px;
    color: #FFF; /* Change icon color if needed */
  }
  .footer_plataforma a:hover {
    text-decoration: underline; /* Add an underline on hover */
  }
 
  }
  

  @media screen and (orientation: landscape) and (max-width: 1023px)  and (min-height:400px){
  /* Footer.css */

  .footer_plataforma {
    position: absolute;
      
    top: 85%;
    left: 1.5%;
      width: 100%;
     
    z-index: 12000;
        height:3%;
  }
  
  .footer_socials_plataforma {
    display: flex;
    justify-content: center;
  }
  
  .social-icons0_plataforma {
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust spacing between icons */
  }
  
  /* Additional styles for icons if needed */
  .social-icons0_plataforma a {
    font-size: 20px;
    color: #FFF; /* Change icon color if needed */
  }
  .footer_plataforma a:hover {
    text-decoration: underline; /* Add an underline on hover */
  }
  }



@media screen and (orientation: portrait) and (max-width: 1023px) and (min-height:1800px) {
/* Footer.css */

.footer_plataforma {
  position: absolute;
    
  top: 87%;
  left: -38.5%;
    width: 100%;
   
    z-index: 12000;
      height:3%;
}

.footer_socials_plataforma {
  display: flex;
  justify-content: center;
}

.social-icons0_plataforma {
  display: flex;
  align-items: center;
  gap: 15px; /* Adjust spacing between icons */
}

/* Additional styles for icons if needed */
.social-icons0_plataforma a {
  font-size: 26px;
  color: #FFF; /* Change icon color if needed */
}
.footer_plataforma a:hover {
  text-decoration: underline; /* Add an underline on hover */
}
}


@media screen and (orientation: portrait) and (max-width: 1023px) and (max-height:1800px) {
  /* Footer.css */

.footer_plataforma {
  position: absolute;
    
  top: 86%;
  left: -38.5%;
    width: 100%;
    
    z-index: 12000;
      height:3%;
}

.footer_socials_plataforma {
  display: flex;
  justify-content: center;
}

.social-icons0_plataforma {
  display: flex;
  align-items: center;
  gap: 15px; /* Adjust spacing between icons */
}

/* Additional styles for icons if needed */
.social-icons0_plataforma a {
  font-size: 26px;
  color: #FFF; /* Change icon color if needed */
}
.footer_plataforma a:hover {
  text-decoration: underline; /* Add an underline on hover */
}
}


@media screen and (orientation: landscape) and (max-width: 1023px)  and (max-height:400px){
  /* Footer.css */

  .footer_plataforma {
    position: absolute;
      
    top: 73%;
    left: -42.5%;
      width: 100%;
      
    z-index: 12000;
        height:3%;
  }
  
  .footer_socials_plataforma {
    display: flex;
    justify-content: center;
  }
  
  .social-icons0_plataforma {
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust spacing between icons */
  }
  
  /* Additional styles for icons if needed */
  .social-icons0_plataforma a {
    font-size: 12px;
    color: #FFF; /* Change icon color if needed */
  }
  .footer_plataforma a:hover {
    text-decoration: underline; /* Add an underline on hover */
  }
 
  }
  

  @media screen and (orientation: landscape) and (max-width: 1023px)  and (min-height:400px){
  /* Footer.css */

  .footer_plataforma {
    position: absolute;
      
    top: 70%;
    left: -41%;
      width: 100%;
      
    z-index: 12000;
        height:3%;
  }
  
  .footer_socials_plataforma {
    display: flex;
    justify-content: center;
  }
  
  .social-icons0_plataforma {
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust spacing between icons */
  }
  
  /* Additional styles for icons if needed */
  .social-icons0_plataforma a {
    font-size: 20px;
    color: #FFF; /* Change icon color if needed */
  }
  .footer_plataforma a:hover {
    text-decoration: underline; /* Add an underline on hover */
  }
  }