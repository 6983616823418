/* Footer.css */

.footer {
  position: absolute;
    
  top: 94%;
  left: 1.5%;
    width: 100%;
    z-index: 12000;
      height:3%;
      
}

.footer_socials {
  display: flex;
  justify-content: center;
}

.social-icons0 {
  display: flex;
  align-items: center;
  gap: 15px; /* Adjust spacing between icons */
}

/* Additional styles for icons if needed */
.social-icons0 a {
  font-size: 20px;
  color: #FFF; /* Change icon color if needed */
}
.footer a:hover {
  text-decoration: underline; /* Add an underline on hover */
}


@media screen and (orientation: portrait) and (max-width: 1023px) and (min-height:1800px) {
/* Footer.css */

.footer {
  position: absolute;
    
  top: 90%;
  left: 1.5%;
    width: 100%;
    
    z-index: 12000;
      height:3%;
}

.footer_socials {
  display: flex;
  justify-content: center;
}

.social-icons0 {
  display: flex;
  align-items: center;
  gap: 15px; /* Adjust spacing between icons */
}

/* Additional styles for icons if needed */
.social-icons0 a {
  font-size: 26px;
  color: #FFF; /* Change icon color if needed */
}
.footer a:hover {
  text-decoration: underline; /* Add an underline on hover */
}
}


@media screen and (orientation: portrait) and (max-width: 1023px) and (max-height:1800px) {
  /* Footer.css */

.footer {
  position: absolute;
    
  top: 86%;
  left: 1.5%;
    width: 100%;
    
    z-index: 12000;
      height:3%;
}

.footer_socials {
  display: flex;
  justify-content: center;
}

.social-icons0 {
  display: flex;
  align-items: center;
  gap: 15px; /* Adjust spacing between icons */
}

/* Additional styles for icons if needed */
.social-icons0 a {
  font-size: 26px;
  color: #FFF; /* Change icon color if needed */
}
.footer a:hover {
  text-decoration: underline; /* Add an underline on hover */
}
}


@media screen and (orientation: landscape) and (max-width: 1023px)  and (max-height:400px){
  /* Footer.css */

  .footer {
    position: absolute;
      
    top: 78%;
    left: 1.5%;
      width: 100%;
      
    z-index: 12000;
        height:3%;
  }
  
  .footer_socials {
    display: flex;
    justify-content: center;
  }
  
  .social-icons0 {
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust spacing between icons */
  }
  
  /* Additional styles for icons if needed */
  .social-icons0 a {
    font-size: 16px;
    color: #FFF; /* Change icon color if needed */
  }
  .footer a:hover {
    text-decoration: underline; /* Add an underline on hover */
  }
 
  }
  

  @media screen and (orientation: landscape) and (max-width: 1023px)  and (min-height:400px){
  /* Footer.css */

  .footer {
    position: absolute;
      
    top: 85%;
    left: 1.5%;
      width: 100%;
     
    z-index: 12000;
        height:3%;
  }
  
  .footer_socials {
    display: flex;
    justify-content: center;
  }
  
  .social-icons0 {
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust spacing between icons */
  }
  
  /* Additional styles for icons if needed */
  .social-icons0 a {
    font-size: 20px;
    color: #FFF; /* Change icon color if needed */
  }
  .footer a:hover {
    text-decoration: underline; /* Add an underline on hover */
  }
  }