/* Global styles for labels */
.label {
  margin-bottom: 5px;
  margin-top: 20px;
  z-index: 102;
}

/* Style for all input fields */
input[type="text"],
textarea {
  width: 29vw;
  font-size: 1vw;
  text-align: right;
  padding: 10px; /* Adjust the padding as needed */
  border: 1px solid #ccc; /* Border color */
  border-radius: 5px; /* Rounded corners */

  z-index: 100002;
}

/* Button styles */
.buy-now-button_r {
  background-color: red;
  color: #fff;
  font-weight: bold;
  padding: 10px 20px; /* Adjust the padding as needed */
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: 30%;
  margin-left: 30%;
  margin-bottom: 0%;
  font-family: Futura;
  margin-top: 10%;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
 
  z-index: 100002;
}

.buy-now-button_r:hover {
  background-color: #0056b3;

  z-index: 10002;
}



@media screen and (orientation: portrait) and (max-width: 1023px) and (min-height:1800px) {
/* Global styles for labels */
.label {
  margin-bottom: 10px;
  margin-top: 10%;
  z-index: 102;
  font-size: 3.2vw;
}

/* Style for all input fields */
input[type="text"],
textarea {
  width: 55vw;
  font-size: 2vw;
  text-align: right;
  padding: 10px; /* Adjust the padding as needed */
  border: 1px solid #ccc; /* Border color */
  border-radius: 5px; /* Rounded corners */

  z-index: 100002;
}

/* Button styles */
.buy-now-button_r {
  background-color: red;
  color: #fff;
  font-weight: bold;
  padding: 10px 20px; /* Adjust the padding as needed */
  border: none;
  cursor: pointer;
  font-size: 25px;
  width: 55%;
  margin-left: 60%;
  margin-bottom: 0%;
  font-family: Futura;
  margin-top: 20%;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
 
  z-index: 100002;
}

.buy-now-button_r:hover {
  background-color: #0056b3;

  z-index: 10002;
}
}


@media screen and (orientation: portrait) and (max-width: 1023px) and (max-height:1800px) {
  /* Global styles for labels */
  .label {
    margin-bottom: 10px;
    margin-top: 5%;
    z-index: 102;
    font-size: 3.2vw;
  }
  
  /* Style for all input fields */
  input[type="text"],
  textarea {
    width: 55vw;
    font-size: 2vw;
    text-align: right;
    padding: 10px; /* Adjust the padding as needed */
    border: 1px solid #ccc; /* Border color */
    border-radius: 5px; /* Rounded corners */
  
    z-index: 100002;
  }
  
  /* Button styles */
  .buy-now-button_r {
    background-color: red;
    color: #fff;
    font-weight: bold;
    padding: 10px 20px; /* Adjust the padding as needed */
    border: none;
    cursor: pointer;
    font-size: 22px;
    width: 50%;
    margin-left: 60%;
    margin-bottom: 0%;
    font-family: Futura;
    margin-top: 20%;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
   
    z-index: 100002;
  }
  
  .buy-now-button_r:hover {
    background-color: #0056b3;
  
    z-index: 10002;
  }
  }


@media screen and (orientation: landscape) and (max-width: 1023px)  and (max-height:400px){
    /* Global styles for labels */
    .label {
      margin-bottom: 2px;
      margin-top: 0%;
      z-index: 102;
      font-size: 1.4vw;
    }
    
    /* Style for all input fields */
    input[type="text"],
    textarea {
      width: 30vw;
      font-size: .8vw;
      text-align: right;
      padding: 4px; /* Adjust the padding as needed */
      border: 1px solid #ccc; /* Border color */
      border-radius: 5px; /* Rounded corners */
      z-index: 100002;
    }
    
    /* Button styles */
    .buy-now-button_r {
      background-color: red;
      color: #fff;
      font-weight: bold;
      padding: 5px 10px; /* Adjust the padding as needed */
      border: none;
      cursor: pointer;
      font-size: 14px;
      width: 40%;
      height: 30px;
      margin-left: 30%;
      margin-bottom: 0%;
      font-family: Futura;
      margin-top: 2%;
      transition: background-color 0.3s ease;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      z-index: 100002;
    }
    
    .buy-now-button_r:hover {
      background-color: #0056b3;
    
      z-index: 10002;
    }
}


@media screen and (orientation: landscape) and (max-width: 1023px)  and (min-height:400px){
  /* Global styles for labels */
  .label {
    margin-bottom: 2px;
    margin-top: 4%;
    z-index: 102;
    font-size: 1.4vw;
  }
  
  /* Style for all input fields */
  input[type="text"],
  textarea {
    width: 30vw;
    font-size: 1.8vw;
    text-align: right;
    padding: 4px; /* Adjust the padding as needed */
    border: 1px solid #ccc; /* Border color */
    border-radius: 5px; /* Rounded corners */
    z-index: 100002;
  }
  
  /* Button styles */
  .buy-now-button_r {
    background-color: red;
    color: #fff;
    font-weight: bold;
    padding: 5px 10px; /* Adjust the padding as needed */
    border: none;
    cursor: pointer;
    font-size: 14px;
    width: 40%;
    height: 30px;
    margin-left: 30%;
    margin-bottom: 0%;
    font-family: Futura;
    margin-top: 12%;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 100002;
  }
  
  .buy-now-button_r:hover {
    background-color: #0056b3;
  
    z-index: 10002;
  }
}