/* RightCard.css */

.right-cardss {
  width: 70%;
  margin-left: 4%;
  margin-top: 50px;
  font-family: futura;
  font-size: 23px;
}

.right-cardss h1 {
  text-align: center;
  margin-right: 5%;
  margin-top: 8%;
  margin-bottom: 20px;
  color: #333;
  font-size: 26px;
  font-family: futura;
  font-weight: bold;
}

.dropdown-toggle {
  background-color:red;
  border-color:red;
  font-size: 18px;
  font-family: futura;
}

.dropdown-menu {
  background-color: #f8f9fa;
}

.dropdown-item {
  color: #333;
  font-size: 18px;
  font-family: futura;
}

.faq-answer {
  margin-top: 20px;
  font-size: 18px;
  font-family: futura;
}

.faq-answer h3 {
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  font-size: 20px;
  font-family: futura;
}

.faq-answer p {
  color: #666;
  font-size: 20px;
  font-family: futura;
}
.custom-dropdown-toggle {
  font-size: 20px;
  padding: 5px 10px;
  /* Add other styles as needed */
}


@media screen and  (min-width: 1023px) and (max-width: 1599px){
/* RightCard.css */

.right-cardss {
  width: 70%;
  margin-left: 4%;
  margin-top: 45px;
}

.right-cardss h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 23px;
  font-family: futura;
  font-weight: bold;
}
.custom-dropdown-toggle {
  font-size: 18px;
  padding: 5px 10px;
  /* Add other styles as needed */
}
.dropdown-toggle {
  background-color:red;
  border-color:red;
  font-size: 16px;
  font-family: futura;
}

.dropdown-menu {
  background-color: #f8f9fa;
}

.dropdown-item {
  color: #333;
  font-size: 16px;
  font-family: futura;
}

.faq-answer {
  margin-top: 20px;
  font-size: 16px;
  font-family: futura;
}

.faq-answer h3 {
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  font-size: 18px;
  font-family: futura;
}

.faq-answer p {
  color: #666;
  font-size: 18px;
  font-family: futura;
}

}


@media screen and (orientation: portrait) and (max-width: 1023px) and (max-height:1800px) {


  .right-cardss {
    width: 90%;
    margin-left: 4%;
    margin-top: -20%;
  }
  
  .right-cardss h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 30px;
    font-family: futura;
    font-weight: bold;
  }
  .custom-dropdown-toggle {
    font-size: 18px;
    padding: 5px 10px;
    /* Add other styles as needed */
  }
  .dropdown-toggle {
    background-color:red;
    border-color:red;
    font-size: 28px;
    font-family: futura;
  }
  
  .dropdown-menu {
    background-color: #f8f9fa;
  }
  
  .dropdown-item {
    color: #333;
    font-size: 23px;
    font-family: futura;
  }
  
  .faq-answer {
    margin-top: 20px;
    font-size: 23px;
    font-family: futura;
  }
  
  .faq-answer h3 {
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    font-size: 23px;
    font-family: futura;
  }
  
  .faq-answer p {
    color: #666;
    font-size: 23px;
    font-family: futura;
  }
  
  }


@media screen and (orientation: portrait) and (max-width: 1023px) and (min-height:1800px) {
  /* RightCard.css */

.right-cardss {
  width: 90%;
  margin-left: 4%;
  margin-top: -20%;
}

.right-cardss h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 30px;
  font-family: futura;
  font-weight: bold;
}
.custom-dropdown-toggle {
  font-size: 18px;
  padding: 5px 10px;
  /* Add other styles as needed */
}
.dropdown-toggle {
  background-color:red;
  border-color:red;
  font-size: 28px;
  font-family: futura;
}

.dropdown-menu {
  background-color: #f8f9fa;
}

.dropdown-item {
  color: #333;
  font-size: 23px;
  font-family: futura;
}

.faq-answer {
  margin-top: 20px;
  font-size: 23px;
  font-family: futura;
}

.faq-answer h3 {
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  font-size: 23px;
  font-family: futura;
}

.faq-answer p {
  color: #666;
  font-size: 23px;
  font-family: futura;
}

}