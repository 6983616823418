/* Logo.css */
.logop {
  position: absolute;
   left: 0.0vw;
   text-align: left; /* Center the content horizontally */
   margin-top: -45%; /* Add margin for spacing from the top */
   margin-left: .5vw; /* Add margin for spacing from the top */
   z-index: 10; /* Ensure the Navbar is above the home carousel */
   max-width:10vw;
   height:auto;
   background-color:transparent;
 
    object-fit: cover;
   /* Your other Navbar styles */
 }
 
 .logop a {
   display: inline-block; /* Display the anchor element as a block to contain the image */
   text-decoration: none; /* Remove underlines from the link */
 
 }
 
 /* Style for the image */
 .logop a img {
   margin-left: .5vw;
   max-width: 13vw; /* Make the image responsive */
   height: auto; /* Maintain the aspect ratio */
   /* Add other styles such as padding, border, etc. */
 
 }
 
 @media screen and (orientation: portrait) and (max-width: 1023px) and (min-height:1800px){
 
  /* Logo.css */
  .logop {
    position: absolute;
     left: 0.0vw;
     text-align: left; /* Center the content horizontally */
     margin-top: -180%; /* Add margin for spacing from the top */
     margin-left: .5vw; /* Add margin for spacing from the top */
     z-index: 10; /* Ensure the Navbar is above the home carousel */
     max-width:10vw;
     height:auto;
     background-color:transparent;
   
      object-fit: cover;
     /* Your other Navbar styles */
   }
   
   .logop a {
     display: inline-block; /* Display the anchor element as a block to contain the image */
     text-decoration: none; /* Remove underlines from the link */
   
   }
   
   /* Style for the image */
   .logop a img {
     margin-left: .5vw;
     max-width: 22vw; /* Make the image responsive */
     height: auto; /* Maintain the aspect ratio */
     /* Add other styles such as padding, border, etc. */
   
   }
   
  
  }
 
 @media screen and (orientation: portrait) and (max-width: 1023px) and (max-height:1800px){
 
 /* Logo.css */
 .logop {
   position: absolute;
    left: 0.0vw;
    text-align: left; /* Center the content horizontally */
    margin-top: -150%; /* Add margin for spacing from the top */
    margin-left: .5vw; /* Add margin for spacing from the top */
    z-index: 10; /* Ensure the Navbar is above the home carousel */
    max-width:10vw;
    height:auto;
    background-color:transparent;
  
     object-fit: cover;
    /* Your other Navbar styles */
  }
  
  .logop a {
    display: inline-block; /* Display the anchor element as a block to contain the image */
    text-decoration: none; /* Remove underlines from the link */
  
  }
  
  /* Style for the image */
  .logop a img {
    margin-left: .5vw;
    max-width: 22vw; /* Make the image responsive */
    height: auto; /* Maintain the aspect ratio */
    /* Add other styles such as padding, border, etc. */
  
  }
  
 
 }
 
 
 @media screen and (orientation: landscape) and (max-width: 1023px)  and (min-height:400px) {
 
  /* Logo.css */
  .logop {
    position: absolute;
     left: 0.0vw;
     text-align: left; /* Center the content horizontally */
     margin-top: -44.5%; /* Add margin for spacing from the top */
     margin-left: .5vw; /* Add margin for spacing from the top */
     z-index: 10; /* Ensure the Navbar is above the home carousel */
     max-width:10vw;
     height:auto;
     background-color:transparent;
   
      object-fit: cover;
     /* Your other Navbar styles */
   }
   
   .logop a {
     display: inline-block; /* Display the anchor element as a block to contain the image */
     text-decoration: none; /* Remove underlines from the link */
   
   }
   
   /* Style for the image */
   .logop a img {
     margin-left: .5vw;
     max-width: 12vw; /* Make the image responsive */
     height: auto; /* Maintain the aspect ratio */
     /* Add other styles such as padding, border, etc. */
   
   }
   
  
  }
  
 @media screen and (orientation: landscape) and (max-width: 1023px)  and (max-height:400px) {
 
  /* Logo.css */
  .logop {
    position: absolute;
     left: 0.0vw;
     text-align: left; /* Center the content horizontally */
     margin-top: -34.5%; /* Add margin for spacing from the top */
     margin-left: .5vw; /* Add margin for spacing from the top */
     z-index: 10; /* Ensure the Navbar is above the home carousel */
     max-width:10vw;
     height:auto;
     background-color:transparent;
   
      object-fit: cover;
     /* Your other Navbar styles */
   }
   
   .logop a {
     display: inline-block; /* Display the anchor element as a block to contain the image */
     text-decoration: none; /* Remove underlines from the link */
   
   }
   
   /* Style for the image */
   .logop a img {
     margin-left: .5vw;
     max-width: 12vw; /* Make the image responsive */
     height: auto; /* Maintain the aspect ratio */
     /* Add other styles such as padding, border, etc. */
   
   }
   
  
  }